import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  createLevel,
  getAllLevels,
  updateLevel,
  getMatricesPreData,
} from "../../../../../redux/features/assessmentToolSlice";
import GlobalSelectField from "../../../../mui/GlobalSelectField";

const CreateUpdateLevel = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading, matricesPreData } = useSelector(
    (state) => state.assessmentTool
  );
  const [open, setOpen] = useState(false);
  const [isTextFieldTrue, setIsTextFieldTrue] = useState(false);
  const [isMatrixClassroom, setIsMatrixClassroom] = useState(false);

  const Schema = Yup.object().shape({
    levelName: Yup.string().required("levelName is required"),
    levelMetrixId: Yup.string().required("levelMetrixId is required"),
    levelCreatedBy: Yup.string().required("levelCreatedBy is required"),
  });
  const profile = JSON.parse(localStorage.getItem("cred")).profile;
  const levelData = [
    { id: "Level One", levelName: "Level One" },
    { id: "Level Two", levelName: "Level Two" },
    { id: "Level Three", levelName: "Level Three" },
    { id: "Level four", levelName: "Level four" },
    { id: "Level five", levelName: "Level five" },
    { id: "Level six", levelName: "Level six" },
  ];

  // const categoryData = [
  //   { id: "Category One", levelName: "Category One" },
  //   { id: "Category Two", levelName: "Category Two" },
  //   { id: "Category Three", levelName: "Category Three" },
  //   { id: "Category Four", levelName: "Category Four" },
  //   { id: "Category Five", levelName: "Category Five" },
  // ];

  const categoryData = [
    { id: "class 1,2", levelName: "class 1,2" },
    { id: "class 3,4", levelName: "class 3,4" },
    { id: "class 5,6", levelName: "class 5,6" },
    { id: "class 7,8", levelName: "class 7,8" },
    { id: "class 9,10", levelName: "class 9,10" },
    { id: "class 11,12", levelName: "class 11,12" },
  ];

  //   Category 1(class 1,2)
  // Category 2(class 3,4)
  // Category 3(class 5,6)
  // Category 4(class 7,8)
  // Category 5(class 9,10)
  // Category 6(class 11,12)

  const formik = useFormik({
    enableReinitialize: open ? true : false,
    initialValues: open
      ? {
          levelName: params ? params.level_name : "",
          // levelName:
          //   params && isMatrixClassroom
          //     ? categoryData
          //     : levelData?.some((level) => level.levelName === params.level_name)
          //     ? params.level_name
          //     : "",
          // levelMetrixId: params ? params.level_metrix_id : "",
          levelMetrixId:
            open &&
            params &&
            matricesPreData?.some(
              (matrix) => matrix.matrix_id === params.level_metrix_id
            )
              ? params.level_metrix_id
              : "",
          levelCreatedBy: profile.userId,
          levelUpdatedBy: profile.userId,
          id: params ? params.level_id : "",
        }
      : {},
    validationSchema: open ? Schema : {},
    onSubmit: (values, { resetForm }) => {
      if (params) {
        dispatch(updateLevel(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllLevels());
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createLevel(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllLevels());
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.levelMetrixId && open) {
      const filter = matricesPreData.find(
        (item) => item.matrix_id == formik.values.levelMetrixId
      );

      if (filter) {
        if (
          filter.course_name === "foundation" ||
          filter.course_name === "Little Genie"
        ) {
          setIsTextFieldTrue(true);
        } else {
          setIsTextFieldTrue(false);
        }

        if (filter.course_name == "Classroom") {
          setIsMatrixClassroom(true);
        } else {
          setIsMatrixClassroom(false);
        }
      }
    }
  }, [formik.values.levelMetrixId, open]);

  return (
    <GlobalDialog
      title={params ? "Update Matrix Level" : "Create Matrix Level"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {params ? (
            <UpdateButton
              size="small"
              disabled={params.has_child_element === 1 ? true : false}
              action={() => {
                dispatch(
                  getMatricesPreData(profile?.departmentId == 22 ? 22 : 221)
                );
                setOpen(true);
              }}
            >
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                dispatch(
                  getMatricesPreData(profile?.departmentId == 22 ? 22 : 221)
                );
                setOpen(true);
              }}
            >
              Add Level
            </AddButton>
          )}
        </>
      }
      content={
        <>
          {open && Object.keys(formik.values).length != 0 ? (
            <Box>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Stack direction={"column"} spacing={2}>
                      <GlobalSelectField
                        options={{
                          formik: formik,
                          label: "Matrix",
                          name: "levelMetrixId",
                          sx: {
                            width: "100%",
                          },
                          data: matricesPreData,

                          keys: {
                            id: "matrix_id",
                            value: "matrix_name",
                          },
                        }}
                      />
                      <Box>
                        {isTextFieldTrue ? (
                          <TextField
                            name="levelName"
                            label="Level Name"
                            onChange={formik.handleChange}
                            value={formik.values.levelName}
                            autoComplete="off"
                            fullWidth
                            error={
                              formik.touched.levelName &&
                              Boolean(formik.errors.levelName)
                            }
                            helperText={formik.errors.levelName}
                          />
                        ) : (
                          <GlobalSelectField
                            options={{
                              formik: formik,
                              label: "Level Name",
                              name: "levelName",
                              sx: {
                                width: "100%",
                              },
                              data: !isMatrixClassroom
                                ? categoryData
                                : levelData,

                              keys: {
                                id: "id",
                                value: "levelName",
                              },
                            }}
                          />
                        )}
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <CancelButton action={() => setOpen(false)}>
                          Cancel
                        </CancelButton>
                        {params ? (
                          <UpdateButton type="submit">
                            {loading ? "Updating..." : "Update"}
                          </UpdateButton>
                        ) : (
                          <SubmitButton type="submit">
                            {loading ? "Saving..." : "Save"}
                          </SubmitButton>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </Form>
              </FormikProvider>
            </Box>
          ) : null}
        </>
      }
    />
  );
};

export default CreateUpdateLevel;
