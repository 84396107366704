import GlobalDialog from '../../components/mui/Dialogue/GlobalDialog'
import { ViewButton } from '../../components/mui/Buttons/Buttons'
import { Autocomplete, Avatar, Box, Button, Paper, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import NoDataFound from '../../components/NodataIndication/NoDataFound'
import Loader3D from '../../components/Loader/Loader3D'
import useResponsive from '../../Hooks/useResponsive'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import ToolTip from '../../components/ToolTip/ToolTip'
import InduvidualAssessmentReport from '../assessmentReportInduvidual/InduvidualAssessmentReport'
import { Icon } from '@iconify/react/dist/iconify.js'
import { getGrievancesJourney } from '../../redux/features/studentPortalSlice'
import { useDispatch } from 'react-redux'

// let grievanceJourneys = [
//   {
//     time: '2024-05-15 14:33:11',
//     requestRaisedBy: 'Mizafa',
//     DepartmentName: 'Technical',
//     performedBy: '',
//     AssignedTo: '',
//     comments: '',
//     assigneeRemarks: '',
//     complaint: 'Lack of personalization',
//     orderNo: 18,
//     status: 'Pending',
//     verificationStatus: 'Not Verified',
//   },
//   {
//     time: '2024-05-15 20:10:28',
//     requestRaisedBy: 'Mizafa',
//     DepartmentName: 'Technical',
//     performedBy: 'Shahida',
//     AssignedTo: 'Subhin',
//     comments: 'Please Do Fast',
//     assigneeRemarks: '',
//     complaint: 'Lack of personalization',
//     orderNo: 18,
//     status: 'Assigned',
//     verificationStatus: 'Not Verified',
//   },
//   {
//     time: '2024-05-17 20:10:28',
//     requestRaisedBy: 'Mizafa',
//     DepartmentName: 'Technical',
//     performedBy: 'Shahida',
//     AssignedTo: 'Subhin',
//     comments: 'Please Do Fast',
//     assigneeRemarks: '',
//     complaint: 'Lack of personalization',
//     orderNo: 18,
//     status: 'In Progress',
//     verificationStatus: 'Not Verified',
//   },
//   {
//     time: '2024-06-01 14:07:35',
//     requestRaisedBy: 'Mizafa',
//     DepartmentName: 'Technical',
//     performedBy: 'Shahida',
//     AssignedTo: 'Subhin',
//     comments: 'Please Do Fast',
//     assigneeRemarks: 'Iam Done',
//     complaint: 'Lack of personalization',
//     orderNo: 18,
//     status: 'Resolved',
//     verificationStatus: ' Not Verified',
//   },
//   {
//     time: '2024-06-01 14:07:35',
//     requestRaisedBy: 'Mizafa',
//     DepartmentName: 'Technical',
//     performedBy: 'Shahida',
//     AssignedTo: 'Subhin',
//     comments: 'Please Do Fast',
//     assigneeRemarks: 'Iam Done',
//     complaint: 'Lack of personalization',
//     orderNo: 18,
//     status: 'Resolved',
//     verificationStatus: 'Verified',
//   },
// ]

const GrievanceJourney = ({grievenceId }) => {
  const [open, setOpen] = useState(false)
  const [direction, setDirection] = useState({})
  const { loading, departments, employees, grievanceJourney } = useSelector((store) => store.studentPortal)
  const mdUp = useResponsive('up', 'md')
  const [modifiedData, setModifiedData] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (mdUp) {
      setDirection('row')
    } else {
      setDirection('column')
    }
  }, [mdUp])


  useEffect(() => {
    if (open) {
      dispatch(getGrievancesJourney(grievenceId))
    }
  }, [open,grievenceId])

  useEffect(() => {
    if (grievanceJourney.length > 0) {
      const modified = grievanceJourney.map((obj, index) => {
        const currentDate = dayjs(obj.time).startOf('day') // Start of day to focus only on the date
        const nextDate = grievanceJourney[index + 1]
          ? dayjs(grievanceJourney[index + 1].time).startOf('day')
          : null
        const daysGap = nextDate ? nextDate.diff(currentDate, 'day') : null

        return {
          ...obj,
          daysGap: daysGap !== null && daysGap > 0 ? `${daysGap} days` : '0 day', // Set only if day has changed
        }
      })

      setModifiedData(modified)
    }
  }, [grievanceJourney])

  return (
    <GlobalDialog
      maxWidth={'md'}
      fullWidth
      title={'Grievance Journey'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          <ViewButton size="small" action={() => setOpen(true)}>
            View
          </ViewButton>
        </>
      }
      content={
        <>
          <motion.div
            initial={{ opacity: 0, rotateY: 90 }}
            animate={{ opacity: 1, rotateY: 0 }}
            exit={{ opacity: 0, rotateY: 90 }}
            transition={{ duration: 1, ease: 'linear' }}
            style={{ flex: 2.5 }}>
            {/* <Paper elevation={5}> */}
            {loading ? (
              <Loader3D />
            ) : grievanceJourney?.length === 0 ? ( //IF THE FETCHED DATA ARRAY LENGTH 0 THEN NO DATA TO DISPLAY SO DISPLAYING THIS COMPONENT
              <Box sx={{ height: '470px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <NoDataFound />
              </Box>
            ) : (
              <Box
                sx={{
                  p: 1,
                  height: '520px',
                  overflowY: 'auto',
                  paddingRight: '10px',
                  scrollbarWidth: 'thin',
                }}>
                <Box sx={{ p: mdUp ? 1 : 0 }}>
                  <Timeline position="alternate">
                    {modifiedData.map((data, index) => (
                      <TimelineItem key={index}>
                        <TimelineOppositeContent
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: index % 2 == 0 ? 'flex-end' : 'flex-start',
                            typography: 'body2',
                            color: 'gray',
                            fontSize: '0.9rem',
                            textAlign: 'right',
                            fontStyle: 'italic',
                            fontWeight: 'lighter',
                          }}>
                          {index !== modifiedData.length - 1 ? data.daysGap : null}{' '}
                          {/* IT WILL AVOID LAST SECTION DAYS GAP VIEW */}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            variant="filled"
                            color="secondary"
                            sx={{
                              mx: '5px',
                              borderWidth: 4,
                              borderColor: 'black',
                              backgroundColor: 'gray',
                            }}
                          />
                          {index !== modifiedData.length - 1 && (
                            <TimelineConnector sx={{ backgroundColor: 'gray', width: 2 }} />
                          )}
                          {/* IT WILL AVOID LAST CONNECTER LINE */}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography
                            variant="h6"
                            component="span"
                            sx={{
                              fontWeight: 'bold',
                              color: 'black',
                              fontSize: '1.1rem',
                            }}>
                            {`${data.status}`}
                          </Typography>
                          <Typography sx={{ fontSize: '0.85rem', color: 'text.secondary', mt: 1 }}>
                            {dayjs(data.time).format('DD-MM-YYYY')}
                          </Typography>
                          {/* {data.status == 'Pending' && (
                            <Typography sx={{ fontSize: '0.85rem', mt: 1, color: 'red' }}>
                              Ticket Raised By : {data.requestRaisedBy}
                            </Typography>
                          )} */}
                          
                          {['Assigned to User','Assigned to Department'].includes(data.status)  && (
                            <Box sx={{ mt: 1 }}>
                            <Typography
                              component={'span'}
                              sx={{ color: '#036706', fontSize: '0.9rem', backgroundColor: '#cff8ca', p: 1 }}>
                              {`Assigned To : ${data.performedTo}`}
                            </Typography>
                          </Box>
                          )}
                                         
                            <Box sx={{ mt: 2 }}>
                              <Typography
                                component={'span'}
                                sx={{ color: '#03045e', fontSize: '0.9rem', backgroundColor: '#caf0f8', p: 1 }}>
                                {`Handled by: ${data.performedBy}`}
                              </Typography>
                            </Box>
                         
                         
                            <Box sx={{ mt: 2 }}>
                              <ToolTip
                                expand={data.comments}
                                content={
                                  <Box
                                    component={'span'}
                                    border={1}
                                    sx={{
                                      borderRadius: '5px',
                                      px: 1,
                                      display: 'inline-flex',
                                      alignItems:'center',
                                      gap:'3px',
                                      justifyContent: index % 2 !== 0 ? 'flex-end' : 'flex-start',
                                    }}>
                                    <Typography variant='button'>Comment</Typography>
                                    <Icon icon="raphael:view" width="25" height="25" style={{ color: 'black' }} />
                                  </Box>
                                }
                              />
                            </Box>
                        

                          {/* {data.AssignedTo && (
                            <Box sx={{ mt: 2 }}>
                              <Typography
                                component={'span'}
                                sx={{ color: '#03045e', fontSize: '0.9rem', backgroundColor: '#caf0f8', p: 1 }}>
                                {`Assigned To: ${data.AssignedTo}`}
                              </Typography>
                            </Box>
                          )} */}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Box>
              </Box>
            )}
            {/* </Paper> */}
          </motion.div>
        </>
      }
    />
  )
}

export default GrievanceJourney
