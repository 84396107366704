// import { Container, Typography, Box } from "@mui/material";
// import React from "react";
// import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
// import Page from "../../../../components/Page";
// import { useLocation } from "react-router-dom";
// import { DataGrid } from "@mui/x-data-grid";
// import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
// import { useState } from "react";
// import { createTableHead } from "../../../../redux/features/attendanceSlice";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import UpdateAttendance from "../../../../components/datagrid/Attendance/UpdateAttendance";
// import { getAttendanceTypes } from "../../../../redux/features/attendanceSlice";

// export default function EditAttendance(params) {
//   const dispatch = useDispatch();
//   const location = useLocation();
//
//   const [gridSize, setGridSize] = useState(5);
//   const [tableHead, setTableHead] = useState([]);
//   const { attendance } = useSelector((state) => state);
//   const preData = useSelector((state) => state.attendance.type);
//
//   useEffect(() => {
//     dispatch(getAttendanceTypes());
//     dispatch(createTableHead("2023/02"));
//     setTableHead(attendance.tableHead);
//   }, []);
//   useEffect(() => {
//     const table = attendance.tableHead.map((item, i) => {
//       if (item.day) {
//         item = {
//           ...item,
//           renderCell: (params) => UpdateAttendance(params, preData),
//         };
//       }
//       return item;
//     });
//     setTableHead(table);
//   }, [attendance]);
//
//   return (
//     <div>
//       <Page>
//         <Container component="main">
//           <Typography variant="h4">Edit Attendance</Typography>
//           <Breadcrumb sx={{ ml: 2 }} />
//           <Box sx={{ width: "100%" }}>
//             <DataGrid
//               loading={location.loading}
//               rows={[location.state]}
//               columns={tableHead}
//               sx={{
//                 borderTopLeftRadius: 0,
//                 borderTopRightRadius: 0,
//               }}
//               pageSize={gridSize}
//               onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
//               autoHeight
//               rowsPerPageOptions={[5, 10, 25, 50]}
//               getRowId={(row) => row.avatar}
//               components={{ Toolbar: CustomGridToolbar }}
//               componentsProps={{
//                 toolbar: {
//                   showQuickFilter: true,
//                   quickFilterProps: { debounceMs: 500 },
//                 },
//               }}
//             />
//           </Box>
//         </Container>
//       </Page>
//     </div>
//   );
// }

import {
  Button,
  Container,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getAttendanceTypes } from "../../../../redux/features/attendanceSlice";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import Page from "../../../../components/Page";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import { editAttendance } from "../../../../redux/features/attendanceSlice";
import { useSnackbar } from "notistack";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Loader from "../../../../components/Loader/Loader";
import { Divider } from "@mui/material";
import { Avatar } from "@mui/material";
import Chip from "@mui/material/Chip";
import useResponsive from "../../../../Hooks/useResponsive";
import dayjs from "dayjs";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";

export default function EditAttendance() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();

  const preData = useSelector((state) => state.attendance.types);
  const loading = useSelector((state) => state.attendance.loading);
  const location = useLocation();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [result, setResult] = useState({
    year: location.state.year,
    month: location.state.month,
    userId: location.state.userId,
  });

  const [age, setAge] = React.useState(
    location.state.attendance.map((item) => item.id)
  );
  useEffect(() => {
    dispatch(getAttendanceTypes(-1));
  }, []);

  useEffect(() => {
    let arr = location.state.attendance.map((item) => item.id);

    setAge(arr);
  }, [location.state.attendance]);

  const handleChange = (event, i) => {
    let ag = [...age];
    ag[i] = event.target.value;

    setAge(ag);

    const Data = {
      year: location.state.year,
      month: location.state.month,
      userId: location.state.userId,
    };
    let name = `a${event.target.name}`;

    setResult({ ...result, [name]: event.target.value });
  };

  useEffect(() => {}, [result]);

  const handleSubmit = () => {
    dispatch(editAttendance(result)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
        navigate(
          "/my-workspace/reporting_head_or_hod/reporting-head-attendance",
          {
            replace: true,
          }
        );
      }
    });
  };

  const handleCancel = () => {
    navigate("/my-workspace/reporting_head_or_hod/reporting-head-attendance", {
      replace: true,
    });
  };

  return (
    <div>
      <GlobalWrapper title="Edit Attendance">
        {loading ? (
          <Loader />
        ) : (
          <Box marginTop={4}>
            <Paper elevation={3} width={"100%"}>
              {/* <Box
              sx={{
                height: 48,
                backgroundColor: "grey.200",
                borderRadius: "8px 8px 0 0",
              }}
            ></Box> */}
              <Box sx={{ padding: 2.5 }}>
                <Paper
                  sx={{
                    backgroundColor: "grey.200",
                    width: "100%",
                  }}
                >
                  <Stack direction={"row"}>
                    <Box sx={{ padding: 2 }}>
                      <Avatar
                        src={`https://dev.teaminterval.net/assets/employee/photo/passport_size/${location.state.avatar}.jpg`}
                        sx={{
                          width: 70,
                          height: 70,
                          border: 3,
                          borderColor: "#e5e8eb",
                        }}
                      />
                    </Box>
                    <Box marginTop={5}>
                      <Typography
                        variant="h7"
                        sx={{
                          fontWeight: "bolder",
                        }}
                      >
                        {location.state.name}
                      </Typography>
                    </Box>
                  </Stack>
                </Paper>
              </Box>
              <Divider
                sx={{ borderBottomWidth: 4, marginTop: 4 }}
                variant="middle"
              />
              {smUp && (
                <Box sx={{ margin: 3, padding: 2 }}>
                  <Grid container spacing={2}>
                    {location.state.attendance.map((item, i) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            minWidth: 120,
                          }}
                        >
                          <Box
                            sx={{
                              padding: 2,
                            }}
                          >
                            <Chip
                              label={`Day ${item.date}`}
                              variant="outlined"
                              size="small"
                              color="primary"
                            />
                          </Box>

                          <Grid item xs={11}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                status
                              </InputLabel>
                              <Select
                                disabled={[12,8,13,14,11,10,9].includes(item.id)}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age[i]}
                                label="Age"
                                onChange={(e) => handleChange(e, i)}
                                variant="filled"
                                name={item.date}
                              >
                                {preData.map((item) => {
                                  return (
                                    <MenuItem

                                      key={item.id}
                                      // value={`${item.id}-${item.date}`}
                                      value={item.id}
                                    >
                                      {item.status}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Grid>
                </Box>
              )}
              {!smUp && (
                <Box
                  sx={{
                    margin: 3,
                    padding: 0,
                    marginLeft: 5,
                  }}
                >
                  <Grid container spacing={2}>
                    {location.state.attendance.map((item, i) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            minWidth: 120,
                          }}
                        >
                          <Box
                            sx={{
                              padding: 2,
                            }}
                          >
                            <Chip
                              label={`Day ${item.date}`}
                              variant="outlined"
                              size="small"
                              color="primary"
                            />
                          </Box>

                          <Grid item xs={11}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age[i]}
                                label="Age"
                                onChange={(e) => handleChange(e, i)}
                                variant="filled"
                                name={item.date}
                              >
                                {preData.map((item) => {
                                  return (
                                    <MenuItem
                                      key={item.id}
                                      // value={`${item.id}-${item.date}`}
                                      value={item.id}
                                    >
                                      {item.status}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Grid>
                </Box>
              )}

              <Divider
                sx={{ borderBottomWidth: 4, marginTop: 4 }}
                variant="middle"
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: 4,
                }}
              >
                <Stack direction={"row"} spacing={2}>
                  <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSubmit}>
                    <Icon
                      icon="mdi:content-save-check-outline"
                      width="25"
                      height="25"
                    />
                    {loading ? "Updating..." : "Update"}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Box>
        )}
      </GlobalWrapper>
    </div>
  );
}
