import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { CancelButton } from "../../../../components/mui/Buttons/Buttons";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { motion } from "framer-motion";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import DayTimePreference from "./DayTimePreference";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  createCartItem,
  getCartItems,
  getleadPreferance,
} from "../../../../redux/features/cartSlice";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import CircularLoader from "../../../../components/Loader/CircularLoader";

const PopuForm = ({
  totalCourses,
  setTotalCourses,
  totalPayingAmount,
  totalDiscountedAmount,
  disabled,
}) => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = ["Malayalam", "English", "Hindi", "Arabic"];
  const [personName, setPersonName] = React.useState([]);
  const location = useLocation();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const { classes, mediums, syllabuses } = useSelector((state) => state.cro);
  const { loading, leadPreferance } = useSelector((state) => state.cart);
  const [open, setOpen] = useState(false);
  const [admissionFee, setAdmissionFee] = React.useState(false);
  const [preferences, setPreferences] = React.useState([
    { day: "", startTime: dayjs(), endTime: dayjs() },
  ]);

  const handleChangeSwitch = (event) => {
    setAdmissionFee(event.target.checked);
  };
  const Schema = Yup.object().shape({
    sessionsPerWeek: Yup.string().required("sessionsPerWeek is required"),
    trainerPreference: Yup.string().required(
      "trainerPrepreference is required"
    ),
    syllabusId: Yup.string().required("syllabus is required"),
    mediumId: Yup.string().required("medium is required"),
    classId: Yup.string().required("class is required"),
    // languageFluency: Yup.string().required("languageFuency is required"),
  });
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      leadId: location?.state.leadId || "",
      paymentMode: 0,
      items: [],
      // ----------------------------------
      sessionsPerWeek: leadPreferance?.sessionsPerWeek || "",
      trainerPreference: leadPreferance?.trainerPreference || "",
      totalFee: "",
      totalPayingFee: "",
      languageFluency: personName || [],
      syllabusId: leadPreferance?.syllabusId || "",
      mediumId: leadPreferance?.mediumId || "",
      classId: leadPreferance?.classId || "",
      preferredDayTime: preferences || [],
      admissionFee: 0,
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      dispatch(createCartItem(values)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          dispatch(getCartItems(values.leadId));
          dispatch(getleadPreferance(values.leadId));
          setTotalCourses([]);
          resetForm();
          setOpen(false);
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    },
  });
  useEffect(() => {
    formik.setFieldValue("totalFee", totalDiscountedAmount);
  }, [totalDiscountedAmount]);
  useEffect(() => {
    formik.setFieldValue("totalPayingFee", totalPayingAmount);
  }, [totalPayingAmount]);
  useEffect(() => {
    formik.setFieldValue("languageFluency", personName);
  }, [personName]);
  useEffect(() => {
    formik.setFieldValue("preferredDayTime", preferences);
  }, [preferences]);
  useEffect(() => {
    setPersonName(leadPreferance?.languageFluency || []);
    if (leadPreferance?.preferredDayTime) {
      const data = leadPreferance?.preferredDayTime.map((item) => ({
        ...item,
        startTime: dayjs(item.startTime),
        endTime: dayjs(item.endTime),
      }));
      setPreferences(data || []);
    }
    formik.setFieldValue("items", totalCourses);
  }, [open]);

  useEffect(() => {
    if (admissionFee) {
      formik.setFieldValue("admissionFee", 500);
    } else {
      formik.setFieldValue("admissionFee", 0);
    }
  }, [admissionFee]);
  const handleClick = () => {
    let hasEmptyFields = false;
    let currency = false;

    if (totalCourses && totalCourses.length > 0) {
      // Perform validation
      const currencyvalidation = totalCourses.some(
        (item) => item.projectId == 1
      );
      if (currencyvalidation) {
        currency = false;
      } else {
        currency = true;
      }
      const subjectValidation = totalCourses.some(
        (item) => item.subjects.length == 0
      );

      if (!subjectValidation) {
        totalCourses.forEach((item) => {
          if (
            !item.MRP ||
            !item.discount ||
            !item.noOfSessions ||
            (!item.paidAmt && item.wishList == false)
          ) {
            enqueueSnackbar("Some fields are empty...!", {
              variant: "error",
            });
            hasEmptyFields = true; // Mark validation failure
          }
        });

        // If any fields are empty, close the dialog
        if (hasEmptyFields) {
          setOpen(false);
        } else {
          setOpen(true);
        }
      } else if (subjectValidation) {
        enqueueSnackbar(
          `${currency ? "Add Currency Type...!" : "Add subject..!"}`,
          {
            variant: "error",
          }
        );
        setOpen(false);
      }
    }
  };

  return (
    <Box>
      <GlobalDialog
        title={"Cart Confirmation Details"}
        open={open}
        setOpen={setOpen}
        closeButton={true}
        fullWidth
        maxWidth={"md"}
        popupButton={
          <Button
            disabled={disabled}
            onClick={handleClick}
            sx={{
              color: "white",
              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
              borderRadius: 3,
            }}
          >
            <Icon icon="mdi:cart-outline" height={22} width={22} />
            Confirm Purchase
          </Button>
        }
        content={
          <Box>
            {open ? (
              <GlobalWrapper>
                <Box
                  sx={{
                    background: smUp
                      ? `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`
                      : "white",
                    padding: smUp ? 3 : 1,
                    borderRadius: 3,
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "10px 100px 50px 10px",
                        //   position: "relative",
                        padding: smUp ? 5 : 2,
                        // overflow: "hidden",
                      }}
                    >
                      <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                          <Box>
                            <Stack direction={"column"} spacing={2}>
                              <Box>
                                <Stack
                                  direction={smUp ? "row" : "column"}
                                  spacing={2}
                                >
                                  <MotionContainer delay={0.8}>
                                    <TextField
                                      name="sessionsPerWeek"
                                      label="session Per Week"
                                      fullWidth
                                      value={formik.values.sessionsPerWeek}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.sessionsPerWeek &&
                                        Boolean(formik.errors.sessionsPerWeek)
                                      }
                                      helperText={formik.errors.sessionsPerWeek}
                                    />
                                  </MotionContainer>
                                  <MotionContainer delay={0.5}>
                                    <GlobalSelectField
                                      options={{
                                        name: "trainerPreference",
                                        formik,
                                        sx: { width: "100%" },
                                        label: "Trainer Preference",
                                        data: [
                                          { id: "Male", gender: "Male" },
                                          { id: "Female", gender: "Female" },
                                          {
                                            id: "No Preference",
                                            gender: "No Preference",
                                          },
                                        ],
                                        keys: {
                                          id: "id",
                                          value: "gender",
                                        },
                                      }}
                                    />
                                  </MotionContainer>
                                </Stack>
                              </Box>

                              <Box>
                                <Stack
                                  direction={smUp ? "row" : "column"}
                                  spacing={2}
                                >
                                  <MotionContainer delay={0.8}>
                                    {" "}
                                    <TextField
                                      variant="filled"
                                      name="totalFee"
                                      label="Total Fee"
                                      fullWidth
                                      value={formik.values.totalFee}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.totalFee &&
                                        Boolean(formik.errors.totalFee)
                                      }
                                      helperText={formik.errors.totalFee}
                                    />
                                  </MotionContainer>
                                  <MotionContainer delay={0.5}>
                                    <TextField
                                      name="totalPayingFee"
                                      label="Total Paying Fee"
                                      variant="filled"
                                      fullWidth
                                      value={formik.values.totalPayingFee}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.totalPayingFee &&
                                        Boolean(formik.errors.totalPayingFee)
                                      }
                                      helperText={formik.errors.totalPayingFee}
                                    />
                                  </MotionContainer>
                                </Stack>
                              </Box>
                              <MotionContainer>
                                <Box>
                                  <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="demo-multiple-chip-label">
                                      Language Fluency
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-chip-label"
                                      id="demo-multiple-chip"
                                      multiple
                                      value={personName}
                                      onChange={handleChange}
                                      input={
                                        <OutlinedInput
                                          id="select-multiple-chip"
                                          label="Language Fuency"
                                        />
                                      }
                                      renderValue={(selected) => (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                          }}
                                        >
                                          {selected.map((value, i) => (
                                            <Box key={i}>
                                              <motion.div
                                                initial={{ opacity: 0, x: -80 }}
                                                animate={{ opacity: 1, x: 0 }}
                                                exit={{ opacity: 0, x: -50 }}
                                                transition={{ duration: 0.4 }}
                                              >
                                                <Chip
                                                  size="small"
                                                  // color="primary"
                                                  sx={{
                                                    background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                                                    color: "white",
                                                  }}
                                                  icon={
                                                    <Icon
                                                      color="white"
                                                      icon="clarity:language-line"
                                                    />
                                                  }
                                                  variant="contained"
                                                  key={value}
                                                  label={value}
                                                />
                                              </motion.div>
                                            </Box>
                                          ))}
                                        </Box>
                                      )}
                                      MenuProps={MenuProps}
                                    >
                                      {names.map((name) => (
                                        <MenuItem
                                          key={name}
                                          value={name}
                                          //   style={getStyles(name, personName, theme)}
                                        >
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </MotionContainer>
                              <MotionContainer delay={0.8}>
                                <DayTimePreference
                                  setPreferences={setPreferences}
                                  preferences={preferences}
                                />
                              </MotionContainer>
                            </Stack>
                            {/* -------------------------------------------------- */}
                            <Box pt={2}>
                              <Stack
                                direction={mdUp ? "row" : "column"}
                                spacing={2}
                              >
                                <MotionContainer delay={0.8}>
                                  <GlobalSelectField
                                    options={{
                                      name: "syllabusId",
                                      formik,
                                      sx: { width: "100%" },
                                      label: "Syllabus",
                                      data: syllabuses,
                                      keys: {
                                        id: "id",
                                        value: "name",
                                      },
                                    }}
                                  />
                                </MotionContainer>
                                <MotionContainer delay={0.5}>
                                  <GlobalSelectField
                                    options={{
                                      name: "mediumId",
                                      formik,
                                      sx: { width: "100%" },
                                      label: "Medium",
                                      data: mediums,
                                      keys: {
                                        id: "id",
                                        value: "name",
                                      },
                                    }}
                                  />
                                </MotionContainer>
                                <MotionContainer delay={0.5}>
                                  <GlobalSelectField
                                    options={{
                                      name: "classId",
                                      formik,
                                      sx: { width: "100%" },
                                      label: "Class",
                                      data: classes,
                                      keys: {
                                        id: "classId",
                                        value: "className",
                                      },
                                    }}
                                  />
                                </MotionContainer>
                              </Stack>
                              <Stack
                                direction={smUp ? "row" : "column"}
                                spacing={2}
                                mt={2}
                              >
                                <MotionContainer delay={0.5}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={admissionFee}
                                          onChange={handleChangeSwitch}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                      }
                                      label="Admissin Fee"
                                    />
                                  </FormGroup>
                                </MotionContainer>
                                {admissionFee ? (
                                  <MotionContainer delay={0.5}>
                                    <TextField
                                      type="number"
                                      name="admissionFee"
                                      label="Admission Fee"
                                      value={formik.values.admissionFee}
                                      onChange={formik.handleChange}
                                      fullWidth
                                    />
                                  </MotionContainer>
                                ) : null}
                              </Stack>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                pt: 2,
                              }}
                            >
                              <Stack direction={"row"} spacing={1}>
                                <CancelButton action={() => setOpen(false)}>
                                  Cancel
                                </CancelButton>
                                <Button
                                  type="submit"
                                  size={smUp ? "medium" : "small"}
                                  sx={{
                                    color: "white",
                                    background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                                    borderRadius: 3,
                                  }}
                                  disabled={loading ? true : false}
                                >
                                  <Icon
                                    icon="mdi:cart-outline"
                                    height={22}
                                    width={22}
                                  />
                                  {loading ? (
                                    <>
                                      Please Wait..!
                                      <CircularLoader />
                                    </>
                                  ) : (
                                    " Confirm and Purchase Courses"
                                  )}
                                </Button>
                              </Stack>
                            </Box>
                          </Box>
                        </Form>
                      </FormikProvider>
                    </Box>
                  </motion.div>
                </Box>
              </GlobalWrapper>
            ) : (
              <Box>null</Box>
            )}
          </Box>
        }
      />
    </Box>
  );
};

export default PopuForm;
