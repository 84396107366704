import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Skeleton } from '@mui/material'
import DashboardCountCardView from '../../../components/Dashboard/HrComponents/DashboardCountCardView'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllhrDashboardCounts,
} from '../../../redux/features/hrDashboard'
import GlobalDialog from '../../../components/mui/Dialogue/GlobalDialog'
import CommonTableModal from './CommonTableModal'
import { employeeTableHeader } from '../../../components/datagrid/hrDashboard/employeeTableHeader'
import FoodManagement from '../../hr/myworkspace/Food/FoodManagement'
import FoodDetails from './FoodDetails'
import PostWorking from '../../user/EmployeeServices/PostWorkingHours/PostWorking'
import dayjs from 'dayjs'
import { getAllDepartmenst } from '../../../redux/features/postWorkingHoursSlice'
import User from '../../hr/myworkspace/userDetails/User'
import Leave from '../../hr/myworkspace/Leave/Leave'
import Present from '../../hr/myworkspace/Present/Present'


const CardViewComponents = React.memo(() => {
  const { resData, hrDashboardCounts, loading } = useSelector((store) => store.hrDashboard)
  const { data, meta } = resData
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [cardName, setCardName] = useState('')
  const [today, setToday] = useState(dayjs())

  const clickFunction = useCallback((title) => {
    //THIS FUNCTION WILL TAKESPLACE TITLE NAME OF EACH CARD TO OPEN IN GLOBAL MODAL
    setCardName(title)
    setOpen(true)
  }, [])

  useEffect(() => {
    dispatch(getAllhrDashboardCounts(today.format('YYYY-MM-DD')))  //API FOR ONLY SHOWING COUNTS ON CARDS
    dispatch(getAllDepartmenst())  //GETTING ALL DEPARTMENTS FOR FILTER USE CASE
  }, [])

  return (
    <Box>
      <GlobalDialog
        open={open}
        setOpen={setOpen}
        fullWidth
        maxWidth={'xl'}
        closeButton={true}
        content={
          <>
            {cardName == 'Total Employees' && <User dashboardView={true} open={open} />}
            {cardName == 'Male Employees' && <User dashboardView={true} open={open} genderFilter={'Male'} />}
            {cardName == 'Female Employees' && <User dashboardView={true} open={open} genderFilter={'Female'} />}
            {cardName == 'Permanent Employees' && (
              <User dashboardView={true} open={open} employeTypeFilter={'Permanent'} />
            )}
            {cardName == 'Notice Period Employees' && (
              <User dashboardView={true} open={open} employeTypeFilter={'Notice Period'} />
            )}
            {cardName == 'Probation Employees' && (
              <User dashboardView={true} open={open} employeTypeFilter={'Probation'} />
            )}
            {cardName == 'Resigned Employees' && (
              <User dashboardView={true} open={open} employeeStatusFilter={'Resigned'} />
            )}
            {cardName == 'Long Leave Employees' && (
              <User dashboardView={true} open={open} employeeStatusFilter={'Long Leave'} />
            )}
            {cardName == 'Today Present' && (
            <User dashboardView={true} open={open} attendenceFilter={'Present Marked'} />
            )}
            
            {cardName == 'Today Leave' && (
            <User dashboardView={true} open={open} leaveFilter={'Compensatory Off'} />
            )}
            {/* {cardName == 'Today Work From Home' && (
              <CommonTableModal
                date={true}
                open={open}
                data={data}
                loading={loading}
                totalNoOfData={meta?.totalRecords}
                dispatchApi={todayAbsentEmployees}
                tableHeader={employeeTableHeader}
              />
            )} */}
            {cardName == 'Lunch Count' && <FoodDetails open={open} lunch={true} />}
            {cardName == 'Evening Food Count' && <FoodDetails open={open} evening={true} />}
            {cardName == 'Post Working Employes' && <PostWorking isHr={true} />}
          </>
        }
      />

      <Grid container spacing={4}>
        {loading ? (
          <DashboardCountCardSkeleton />
        ) : (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Total Employees"
                total={hrDashboardCounts?.TotalEmployees}
                icon={'clarity:group-solid'}
                color="t1"
                clickFunction={clickFunction}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Male Employees"
                total={hrDashboardCounts?.totalMaleEmployees}
                color="t2"
                icon={'ic:outline-male'}
                clickFunction={clickFunction}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Female Employees"
                total={hrDashboardCounts?.totalFemaleEmployees}
                color="t3"
                icon={'game-icons:female'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Permanent Employees"
                total={hrDashboardCounts?.permanentEmployee}
                color="t4"
                icon={'game-icons:strong'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Notice Period Employees"
                total={hrDashboardCounts?.noticeEmployee}
                color="z5"
                icon={'ooui:user-temporary-rtl'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Probation Employees"
                total={hrDashboardCounts?.probationEmployee}
                color="z6"
                icon={'fluent-mdl2:temporary-user'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Long Leave Employees"
                total={hrDashboardCounts?.totalLongLeaveEmployees}
                color="z7"
                icon={'lsicon:user-leave-filled'}
                clickFunction={clickFunction}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Resigned Employees"
                total={hrDashboardCounts?.totalResignedEmployees}
                color="z8"
                icon={'streamline:emergency-exit-solid'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Lunch Count"
                total={hrDashboardCounts?.lunch_count}
                color="p1"
                icon={'fluent:food-32-filled'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Evening Food Count"
                total={hrDashboardCounts?.evening_food_count}
                color="p2"
                icon={'cil:fastfood'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Post Working Employes"
                total={hrDashboardCounts?.postWorkingHours}
                color="p3"
                icon={'arcticons:nightmode'}
                clickFunction={clickFunction}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Today Present"
                total={hrDashboardCounts?.today_present}
                color="p4"
                icon={'ic:round-event-available'}
                clickFunction={clickFunction}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Today Leave"
                total={hrDashboardCounts?.totalLeaves}
                color="p6"
                icon={'iconamoon:calendar-remove-bold'}
                clickFunction={clickFunction}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <DashboardCountCardView
                title="Today Work From Home"
                total={hrDashboardCounts?.totalWFH}
                color="white"
                icon={'mdi:home-account'}
                clickFunction={clickFunction}
              />
            </Grid> */}
       
          </>
        )}
      </Grid>
    </Box>
  )
})
const DashboardCountCardSkeleton = () => {
  return (
    <Box sx={{ px: 4 }}>
      <Grid container spacing={3}>
        {Array.from(new Array(16)).map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Skeleton
              animation="wave"
              sx={{
                width: 270,
                height: 200,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
export default CardViewComponents
