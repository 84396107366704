import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiRequest from '../../api/request'

const dataMapper = (body, dbMapper) => {
  return body.map((item) => {
    const mappedItem = {};
    Object.keys(item).forEach((key) => {
      const newKey = dbMapper[key] || key;
      mappedItem[newKey] = item[key];
    });
    return mappedItem;
  });
};


const initialState = {
  loading: false,
  resData: {
    data: {},
    meta: {},
  },
  departments: [],
}

export const getAllPostWorkingHours = createAsyncThunk(
  'postWorkingHoursSlice/getAllPostWorkingHours',
  async (dispatchParams) => {    
    const { selectedDate, paginationData, departmentName } = dispatchParams
    const url =`general/pwh/${selectedDate}?page=${paginationData?.pageNum}&limit=${paginationData?.gridSize}&search=${encodeURIComponent(paginationData?.search)}`
    const res = await apiRequest({
      method: "get",
      url:url
    });
    return res;
  },
)

export const getAllPostWorkingHoursForHr = createAsyncThunk(
  'postWorkingHoursSlice/getAllPostWorkingHoursForHr',
  async (dispatchParams) => {
    const { selectedDate, paginationData, departmentNames } = dispatchParams
    let departmentFilter='filters[dept]=equals:'
    if(departmentNames?.length>0){
      departmentFilter += departmentNames.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
    }
    const url =`general/pwh/all/${selectedDate}?page=${paginationData?.pageNum}&limit=${paginationData?.gridSize}&search=${encodeURIComponent(paginationData?.search)}${departmentNames.length>0?`&${departmentFilter}`:""}`

    const res = await apiRequest({
      method: "get",
      url: url
    });
    return res;
  },
)


export const getAllDepartmenst = createAsyncThunk('postWorkingHoursSlice/getAllDepartmenst', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `departments/`,
  })
  return res
})

export const createPostWorkingRequest = createAsyncThunk(
  'postWorkingHoursSlice/createPostWorkingRequest',
  async (data) => {
    const res = await apiRequest({
      method: "post",
      url: `general/pwh`,
      data: data,
    });
    return res;
  },
)

export const updatePostWorking = createAsyncThunk('postWorkingHoursSlice/updatePostWorking', async (data) => {
  const res = await apiRequest({
    method: 'patch',
    url: `general/pwh/${data.id}`,
    data: data,
  })
  return res
})

export const deleteWorkingHourRequest = createAsyncThunk(
  'postWorkingHoursSlice/deleteWorkingHourRequest',
  async (id) => {
    const res=await apiRequest({
      method:"delete",
      url:`general/pwh/${id}`
    })
    return res
  },
)

export const PostWorkingApproveOrReject = createAsyncThunk(
  'postWorkingHoursSlice/PostWorkingApproveOrReject',
  async (data) => {
    const res = await apiRequest({
      method: "post",
      url:`general/pwh/approve`,
      data: data,
    });
    return res;
  },
)


const postWorkingHoursSlice = createSlice({
  name: 'postWorkingHoursSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //...............................getAllPostWorkingHours......................//
    builder.addCase(getAllPostWorkingHours.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getAllPostWorkingHours.fulfilled, (state, action) => {
      // const mockResponseData = [
      //   {
      //     id: 1,
      //     sl_no: 1,
      //     date: '2024-10-01',
      //     name: 'Subhin',
      //     department: 'Technical',
      //     status: 'pending',
      //     from: '20:00',
      //     to: '21:00',
      //     remarks: 'Ui designing pending',
      //     approver: 1,
      //   },
      // ]

      // const mockDbMapper = { name: "studentName", department: "studentDepartment" }
      return {
        ...state,
        loading: false,
        resData:{
          ...state.resData,
          data:action.payload.data,
          meta:action.payload.meta
        }
      }
    })

        //...............................getAllPostWorkingHours FOR HR......................//
        builder.addCase(getAllPostWorkingHoursForHr.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          }
        })
        builder.addCase(getAllPostWorkingHoursForHr.fulfilled, (state, action) => {
          return {
            ...state,
            loading: false,
            resData:{
              ...state.resData,
              data:{data:action.payload.data},
              meta:action.payload.meta
            }
          }
        })
    
    //...............................GET ALL DEPARTMENTS......................//
    builder.addCase(getAllDepartmenst.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getAllDepartmenst.fulfilled, (state, action) => {
      return {
        ...state,
        departments: action.payload?.data?.departments,
        loading: false,
      }
    })

    //...............RAISING A REQUEST...........................
    builder.addCase(createPostWorkingRequest.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(createPostWorkingRequest.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

    //...............DELETING A REQUEST...........................
    builder.addCase(deleteWorkingHourRequest.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(deleteWorkingHourRequest.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

    //...............APPROVE OR REJECT A REQUEST...........................
    builder.addCase(PostWorkingApproveOrReject.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(PostWorkingApproveOrReject.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

       //...............UPDATE A REQUEST...........................
       builder.addCase(updatePostWorking.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        }
      })
  
      builder.addCase(updatePostWorking.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        }
      })
  },
})

export default postWorkingHoursSlice.reducer
