import React, { memo, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { border, styled, useTheme } from '@mui/system'
import { Icon } from '@iconify/react'
import { lighten, darken } from '@mui/material/styles'
import GlobalDialog from '../../mui/Dialogue/GlobalDialog'

const GradientCard = styled(Box)(({ basecolor, theme }) => {
  return {
    background: `linear-gradient(to right, ${lighten(basecolor, 0.5)}, ${darken(basecolor, 0.2)})`,
    color: darken(basecolor, 0.7), //AMOUNT OF DARKENING COLOR SCALE FROM 0-1
    padding: '16px',
    height: theme.spacing(16),
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition for hover
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '6px 6px 4px 0 black', // x,y,blurRadius,spreadRadius,color
    },
  }
})

const IconWrapperStyle = styled('div')(({ theme, basecolor }) => {
  return {
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    background: `linear-gradient(to left, ${lighten(basecolor, 0.5)}, ${darken(basecolor, 0.3)})`,
  }
})

const DashboardCountCardView = ({ title, total, icon, color, clickFunction }) => {
  const colorMap = {
    b1: '#0077b6',
    b2: '#0096c7',
    b3: '#00b4d8',
    b4: '#48cae4',
    v1: '#7251b5',
    v2: '#815ac0',
    v3: '#9163cb',
    v4: '#a06cd5',
    t1: '#14746f',
    t2: '#248277',
    t3: '#358f80',
    t4: '#469d89',
    z1: '#0E94A0',
    z2: '#16D9EB',
    z3: '#12C0CF',
    z4: '#16D9EB',
    z5: '#33DEED',
    z6: '#50E3F0',
    z7: '#6EE7F2',
    z8: '#8BECF5',
    z9: '#A8F1F7',
    p1: '#757bc8',
    p2: '#8187dc',
    p3: '#8e94f2',
    p4: '#9fa0ff',
    p5: '#ada7ff',
    p6:'#efa7ff',
    red: '#ff99c8',
    cyan: '#fcf6bd',
    blue: '#2196f3',
    lightBlue: '#d0f4de',
    skyBlue: '#a9def9',
    green: '#4caf50',
    orange: '#ff9800',
    purple: '#800080',
    silver: '#2d2828',
    fuchsia: '#ac288f',
    darkGreen: '#5d602b',
    darkYellow: '#ad9031',
    grey: '#717054',
    total: "#add0dd",
    progress: "#fcff61",
    pending: "#ff9061",
    completed: "#62ff61",
    assigned: "#ffc261",
    rejected: "#ff6161",
    resolved: "#c5ff61"




  }
  const baseColor = colorMap[color] || `#${Math.floor(Math.random() * 16777215).toString(16)}`

  return (
    <>
      <GradientCard basecolor={baseColor} onClick={() => clickFunction(title)}>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="h4" fontWeight="bold">
            {total}
          </Typography>
        </Box>
        <IconWrapperStyle basecolor={baseColor}>
          <Icon icon={icon} width={40} height={40} />
        </IconWrapperStyle>
      </GradientCard>
      <Box></Box>
    </>
  )
}

export default memo(DashboardCountCardView)
