import { GrievanceType } from '../../../views/StudentPortal/Tickets/Admin/Tickets'

const grievanceFind = (type, subType) => {
  const typeObj = GrievanceType.find((typ) => typ.id == type)
  if (!typeObj) return { typeName: 'Unknown Type', subTypeName: 'Unknown SubType' }
  const subTypeObj = typeObj.subTypes.find((sub) => sub.id == subType)
  return { typeName: typeObj.name, subTypeName: subTypeObj.name }
}

export const ticketTableHeader = [
  { field: 'sl_no', headerName: 'Sl No', width: 120 },
  { field: 'studentName', headerName: 'Student Name', width: 250 },
  { field: 'courseName', headerName: 'Course Name', width: 250 },
  { field: 'createdDate', headerName: 'Request Raised On', width: 250 },

  {
    field: 'type',
    headerName: 'Grievence Type',
    width: 250,
    renderCell: (params) => {
      const { type, subType } = params.row
      const { typeName } = grievanceFind(type, subType)
      return typeName
    },
  },
  {
    field: 'subType',
    headerName: 'Grievence SubType',
    width: 250,
    renderCell: (params) => {
      const { type, subType } = params.row
      const { subTypeName } = grievanceFind(type, subType)
      return subTypeName
    },
  },
  { field: 'departmentName', headerName: 'Department Name', width: 250 },
  { field: 'status', headerName: 'Status', width: 250 },
  { field: 'assignedtoName', headerName: 'Assigned To', width: 150 },
  { field: 'description', headerName: 'Description', width: 150 },
]
