import { Icon } from '@iconify/react/dist/iconify.js'
import { Box } from '@mui/material'
import ToolTip from '../../ToolTip/ToolTip'
import noImageSrc from '../../../../src/img/NoImageAvailable.jpg' 
import UserAction from '../../../views/hr/myworkspace/userDetails/UserAction'

const toolTipFunction=(hover,content)=>{
  return <ToolTip expand={hover} content={content||hover} />
}

export const employeeTableHeader = [
  { field: 'sl_no', headerName: 'Serial No', width: 80 },
  { field: 'employeeID', headerName: 'Employee Id', width: 100 },
  { field: 'name', headerName: 'Name', width: 250 },
  {
    field: 'photo',
    headerName: 'Photo',
    width: 100,
    renderCell: (params) => {
      return (
        <div
          key={params.row.sl_no}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%',padding:"4px"}}>
          <img src={`${params.row.photo ? `https://workspace.teaminterval.net/assets/employee/photo/passport_size/${params.row.photo}.jpg`:noImageSrc} `} style={{ width: '100%', height: '100%', objectFit: 'contain' }} 
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = noImageSrc;
          }}/>
          {/* <img src={noImageSrc } style={{ width: '100%', height: '100%', objectFit: 'contain' }} /> */}

        </div>
      )
    },
  },
  { field: 'designation', headerName: 'Designation', width: 250 },
  { field: 'wingName', headerName: 'Wing Name', width: 250 },
  { field: 'reportingHead', headerName: 'Reporting Head', width: 250 },
  { field: 'jod', headerName: 'Date Of Joining', width: 150 },
  {
    field: 'department',
    headerName: 'Department',
    width: 250,
  },
  { field: 'EmploymentType', headerName: 'Employement Type', width: 180 },
  { field: 'sex', headerName: 'Gender', width: 150 },
  { field: 'status', headerName: 'Status', width: 150 },
  { field: 'parentName', headerName: 'Parent Name', width: 250 },
  { field: 'parentMob', headerName: 'Parent Number', width: 150 },
  { field: 'doteOfBirth', headerName: 'Date Of Birth', width: 150 },
  { field: 'bloodGroup', headerName: 'Blood Group', width: 120 },
  // { field: 'maritialStatus', headerName: 'Maritial Status', width: 120 },
  {
    field: 'permenantAdrress',
    headerName: 'Permenant Address',
    width: 450,
    renderCell: (params) => toolTipFunction(params.row.permenantAdrress),
  },
  {
    field: 'communicationAddress',
    headerName: 'Communication Address',
    width: 450,
    renderCell: (params) => toolTipFunction(params.row.communicationAddress),
  },
  { field: 'mobNo', headerName: 'Contact Number', width: 150 },
  { field: 'officialEmail', headerName: 'Official Mail', width: 250 },
  { field: 'personalEmail', headerName: 'Personal Mail', width: 250 },
  { field: 'course', headerName: ' Qualification', width: 250 },

  { field: 'bankName', headerName: 'Bank Name', width: 200 },
  { field: 'bankBranch', headerName: 'Bank Branch', width: 200 },
  { field: 'ifsc', headerName: 'IFSC', width: 150 },
  { field: 'accountNo', headerName: 'Account Number', width: 150 },
  { field: 'accountHolder', headerName: 'Account Holder', width: 230 },
  { field: 'aadhaar', headerName: 'Adhar', width: 150 },
  { field: 'pan', headerName: 'Pan Card', width: 150 },
  { field: 'pf', headerName: 'PF', width: 150 },
  { field: 'esi', headerName: 'ESI', width: 150 },
  // { field: 'lastUpdatedOn', headerName: 'Last Updated On', width: 150 },
  { field: 'leaveStatus', headerName: 'Leave Status', width: 150 },
  { field: 'AttendanceStatus', headerName: 'Attendence Status', width: 220 },
  {
    field: 'Edit',
    headerName: 'Action',
    width: 100,
    renderCell: (params) => <UserAction id={params.row.employeeID}/>,
  },
]
