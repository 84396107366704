import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiRequest from '../../api/request'

const initialState = {
  loading: false,
  resData: {
    data: [],
    meta: {},
  },
  dashboardCounts: {},
  departments: [],
  employees: [],
  grievanceJourney:[]
}
export const getAllGrievancesDashboardCounts = createAsyncThunk(
  'studentPortalSlice/getAllGrievancesDashboardCounts',
  async () => {
    const res = await apiRequest({
      method: 'get',
      url: `grievance/dashboard-count`,
    })
    return res
  },
)

export const getAllGrievancesTickets = createAsyncThunk(
  'studentPortalSlice/getAllGrievancesTickets',
  async (dispatchParams) => {
    const { grievenceTypeId, paginationData, status,fromDate,toDate } = dispatchParams
     
      let statusFilter = 'filters[status]=equals:'
      let grievanceFilter = 'filters[type]=equals:'

      if(status?.length>0){
        statusFilter += status.map((Name)=>`${Name}`).join(encodeURIComponent('+'))
      }
      if(grievenceTypeId?.length>0){
        grievanceFilter += grievenceTypeId.map((id)=>`${id}`).join(encodeURIComponent('+'))
      }
    const url = `grievance/?page=${paginationData?.pageNum}&limit=${
      paginationData?.gridSize
    }&search=${encodeURIComponent(paginationData?.search)}${status?.length>0 ? `&${statusFilter}`:""}${grievenceTypeId?.length>0?`&${grievanceFilter}`:""}${fromDate?`&filters[createdDate]=greater_than_or_equal:${fromDate}`:""}${toDate?`&filters[createdDate]=less_than_or_equal:${toDate}`:""}`
    const res = await apiRequest({
      method: 'get',
      url:url,
    })
    return res
  },
)


export const getGrievancesJourney = createAsyncThunk(
  'studentPortalSlice/getGrievancesJourney',
  async (id) => {
    const res = await apiRequest({
      method: 'get',
      url: `grievance/${id}`,
    })
    return res
  },
)

export const getAllDepartments = createAsyncThunk('studentPortalSlice/getAllDepartments', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `departments/`,
  })
  return res
})

export const getEmployeesUnderTheDepartment = createAsyncThunk(
  'studentPortalSlice/getEmployeesUnderTheDepartment',
  async (id) => {
    const res = await apiRequest({
      method: 'get',
      url: `general/empolyee-under-department/${id}?limit=no`,
    })
    return res
  },
)

export const updateAssignation =createAsyncThunk('studentPortalSlice/updateAssignation', async (data) => {
  const res = await apiRequest({
    method: 'patch',
    url: `grievance/assign/${data.id}`,
    data: data,
  })
  return res
})


const studentPortalSlice = createSlice({
  name: 'studentPortalSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //..................GETING ALL TICKETS ................
    builder.addCase(getAllGrievancesTickets.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getAllGrievancesTickets.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        resData: {
          ...state.resData,
          data: action.payload?.data,
          meta: action.payload?.meta,
        },
      }
    })

    //..................GETING ALL DASHBOARD COUNTS ................
    builder.addCase(getAllGrievancesDashboardCounts.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getAllGrievancesDashboardCounts.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        dashboardCounts: action.payload?.data,
      }
    })
    //...............................GET ALL DEPARTMENTS......................//
    builder.addCase(getAllDepartments.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getAllDepartments.fulfilled, (state, action) => {
      return {
        ...state,
        departments: action.payload?.data?.departments,
        loading: false,
      }
    })

        //...............................GET ALL Grievance Journey......................//
        builder.addCase(getGrievancesJourney.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          }
        })
        builder.addCase(getGrievancesJourney.fulfilled, (state, action) => {
          return {
            ...state,
            grievanceJourney: action.payload?.data,
            loading: false,
          }
        })
    //...............................GET ALL EMPLOYEES UNDER DEPARTMENT......................//
    builder.addCase(getEmployeesUnderTheDepartment.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getEmployeesUnderTheDepartment.fulfilled, (state, action) => {
      return {
        ...state,
        employees: action.payload?.data,
        loading: false,
      }
    })


       //............... UPDATE ASSIGNATION ...........................
       builder.addCase(updateAssignation.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        }
      })
  
      builder.addCase(updateAssignation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        }
      })
  },
})

export default studentPortalSlice.reducer
