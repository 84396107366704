import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../mui/Dialogue/GlobalDialog'
import { UpdateButton } from '../../../mui/Buttons/Buttons'
import { Alert, Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  getAllDepartments,
  getAllGrievancesTickets,
  getEmployeesUnderTheDepartment,
  updateAssignation,
} from '../../../../redux/features/studentPortalSlice'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import GlobalSelectField from '../../../mui/GlobalSelectField'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
const TicketAction = ({ row, dispatchParams, statusJson, deptId }) => {
  const userProfile = JSON.parse(localStorage.getItem('cred')).profile
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { loading, departments, employees } = useSelector((store) => store.studentPortal)
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const [open, setOpen] = useState(false)

  const initialValues = {
    // status: row ? statusJson?.find((sts) => sts.value == row.status)?.id : '',
    status: row ? row.statusId : '',
    comment: '',
    departmentId: row ? row?.departmentId : '',
    assignedToId: row ? row?.assignedtoId : '',
  }
  const schema = Yup.object({
    // departmentId: Yup.number().required('Department Required'),
    // assignedTo: Yup.number().required('User Required'),
    // status: Yup.number().required('Status Required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      if (row?.assignedtoId && row?.departmentId != values.departmentId) {
        //IF ALREADY ASSIGND TO AN EMPLOYEE THEN IF CHANGED TO ANOTHER DEPARTMENT THEN EMPLOYEE ID IS REPLACED BY NULL VALUE
        values.assignedToId = null
      }
      const updatedData = {
        ...values,
        id: row.grivanceId,
        depatmentId: values.departmentId,
        assignedTo: values.assignedToId,
      }

      dispatch(updateAssignation(updatedData)).then((res) => {
        if (res.payload.status === 'error') {
          enqueueSnackbar(res.payload.message, {
            variant: 'error',
          })
        } else if (res.payload.status === 'success') {
          handleCancel()
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
          enqueueSnackbar(res.payload.message, {
            variant: 'success',
          })
          dispatch(getAllGrievancesTickets(dispatchParams))
        }
      })
    },
  })

  useEffect(() => {
    if (open && departments.length == 0) {
      dispatch(getAllDepartments())
    }
  }, [open, departments, formik.values.departmentId])

  useEffect(() => {
    //IT WILL CALL THE DEPARTMENT EMPLOYEES WHEN MODAL OPEN AND DEPARTMENT ID IS FILLED AND STATUS IS 2 (ASSIGNED TO USER)
    if (open && formik.values.departmentId && formik.values.status == 2) {
      dispatch(getEmployeesUnderTheDepartment(formik.values.departmentId))
    }
  }, [open, formik.values.departmentId, formik.values.status])

  const handleCancel = () => {
    setOpen(false)
    formik.resetForm()
  }
  const handleDepartmentChange = (e, option) => {
    formik.setFieldValue('departmentId', option?.departmentId)
  }
  const handleUserChange = (e, option) => {
    formik.setFieldValue('assignedToId', option?.userId)
  }
  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth
      title={'Action'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          <UpdateButton
            //   disabled={row.status !== 'Pending'}
            size="small"
            action={() => setOpen(true)}>
            Assign
          </UpdateButton>
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              {!deptId && (
                <Box mb={3}>
                  <Alert severity="warning">
                    <Typography>
                      You are not role-tagged. Please contact the HR team to get role-tagged, after which you can assign to a user.
                    </Typography>
                  </Alert>
                </Box>
              )}
              <Stack gap={2}>
                <GlobalSelectField
                  disabled={loading}
                  options={{
                    formik: formik,
                    label: 'Status',
                    name: 'status',
                    sx: {
                      width: '100%',
                    },
                    data: statusJson,

                    keys: {
                      id: 'id',
                      value: 'value',
                    },
                  }}
                />
                <Box>
                  <Box sx={{ flex: 1 }}>
                    <Autocomplete
                      name="departments"
                      options={departments}
                      getOptionLabel={(option) => option.departmentName || ''}
                      value={departments?.find((dept) => dept?.departmentId == formik.values.departmentId) || null}
                      onChange={handleDepartmentChange}
                      renderInput={(params) => (
                        <TextField
                          disabled
                          fullWidth
                          autoComplete="off"
                          {...params}
                          label="Departments"
                          error={formik.touched.departmentId && Boolean(formik.errors.departmentId)}
                          helperText={formik.touched.departmentId && formik.errors.departmentId}
                        />
                      )}
                    />
                  </Box>
                </Box>

                {formik.values.status == 2 && (
                  <Box>
                    <Box sx={{ flex: 1 }}>
                      <Autocomplete
                        disabled={formik.values.departmentId != deptId || loading}
                        name="userId"
                        options={employees}
                        getOptionLabel={(option) => option.userName || ''}
                        value={employees?.find((dept) => dept?.userId == formik.values.assignedToId) || null}
                        onChange={handleUserChange}
                        renderInput={(params) => (
                          <TextField
                            disabled
                            fullWidth
                            autoComplete="off"
                            {...params}
                            label="Assigned To"
                            error={formik.touched.assignedToId && Boolean(formik.errors.assignedToId)}
                            helperText={formik.touched.assignedToId && formik.errors.assignedToId}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}

                <Box>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="comment"
                    label="Comment"
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.comment && Boolean(formik.errors.comment)}
                    helperText={formik.touched.comment && formik.errors.comment}
                  />
                </Box>
                <Box>
                  <UpdateButton disabled={loading} type="submit">
                    {loading ? 'Submitting...' : 'Submit'}
                  </UpdateButton>
                </Box>
              </Stack>
            </Form>
          </FormikProvider>
        </>
      }
    />
  )
}

export default TicketAction
