import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import DashboardCountCardView from '../../components/Dashboard/HrComponents/DashboardCountCardView'
import Tickets from './Tickets/Admin/Tickets'
import GlobalWrapper from '../../components/Wrapper/GlobalWrapper'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getAllGrievancesDashboardCounts } from '../../redux/features/studentPortalSlice'
import { useSelector } from 'react-redux'

const StudentPortalDashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch('')
    const { dashboardCounts, loading } = useSelector((store) => store.studentPortal)
  useEffect(() => {
    dispatch(getAllGrievancesDashboardCounts())
  }, [])

  
  return (
    <GlobalWrapper
      title={"Dashboard"}>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Total Tickets"
              total={dashboardCounts['total']}
              icon={'bi:stack'}
              color="total"
              clickFunction={(title) => navigate('/student-portal/tickets')}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Pending Assignment"
              total={dashboardCounts['Assigned to Department']}
              color="pending"
              icon={'material-symbols:pending-outline'}
              clickFunction={(title) => navigate('/student-portal/tickets?status=Assigned to Department')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Tickets Assigned to Users"
              total={dashboardCounts['Assigned to User']}
              color="assigned"
              icon={'raphael:employee'}
              clickFunction={(title) => navigate('/student-portal/tickets?status=Assigned to User')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="In Progress"
              total={dashboardCounts['Working is in Progress']}
              color="progress"
              icon={'lets-icons:progress'}
              clickFunction={(title) => navigate('/student-portal/tickets?status=Working is in Progress')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Resolved"
              total={dashboardCounts["Resolved"]}
              color="resolved"
              icon={'lsicon:order-done-filled'}
              clickFunction={(title) => navigate('/student-portal/tickets?status=Resolved')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Rejected"
              total={dashboardCounts["Rejected with Comment"]}
              color="rejected"
              icon={'fluent:text-change-reject-24-filled'}
              clickFunction={(title) => navigate('/student-portal/tickets?status=Rejected with comment')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DashboardCountCardView
              title="Closed"
              total={dashboardCounts["Closed"]}
              color="completed"
              icon={'fluent-mdl2:completed-solid'}
              clickFunction={(title) => navigate('/student-portal/tickets?status=Closed')}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Tickets dashboard={true} />
      </Box>
    </GlobalWrapper>
  )
}

export default StudentPortalDashboard
