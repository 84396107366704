import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import GlobalDialog from '../../mui/Dialogue/GlobalDialog'
import { Box, Button, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import { SubmitButton } from '../../mui/Buttons/Buttons'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'
import { getAllGrievancesTickets, updateAssignation } from '../../../redux/features/studentPortalSlice'

const Comment = ({ row, dispatchParams}) => {
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { loading } = useSelector((store) => store.studentPortal)

  const initialValues = {
    comment: '',
  }
  const schema = Yup.object({
    comment: Yup.string().required('Comment Required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(updateAssignation({...values,id:row?.grivanceId,status:8})).then((res) => {
        if (res.payload.status === 'error') {
          enqueueSnackbar(res.payload.message, {
            variant: 'error',
          })
        } else if (res.payload.status === 'success') {
          handleCancel()
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
      
          enqueueSnackbar(res.payload.message, {
            variant: 'success',
          })
      dispatch(getAllGrievancesTickets(dispatchParams))
        }
      })
    },
  })
  const handleCancel = () => {
    setOpen(false)
    formik.resetForm()
  }
  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth
      title={'Add Comment'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
            Add Comment
          </Button>
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Box>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="comment"
                  label="Comment"
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment}
                />
              </Box>
              <Box sx={{mt:2}}>
                <SubmitButton disabled={loading} type="submit">
                  {loading ? 'Commenting...' : 'Add Comment'}
                </SubmitButton>
              </Box>
            </Form>
          </FormikProvider>
        </>
      }
    />
  )
}

export default Comment
