import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import { Box, Paper, Stack } from '@mui/material'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import GlobalMultiSelect from '../../../../components/mui/GlobalMultiSelect'
import dayjs from 'dayjs'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import ActionDetails from '../../../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../../../components/mui/GlobalAnchor/GlobalAnchorList'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  getAllGrievancesTickets,
  getAllGrievancesTicketsForAssignee,
} from '../../../../redux/features/studentPortalSlice'
import { ticketTableHeader } from '../../../../components/datagrid/studentPortal/ticketTableHeader'
import HeadOrAssigneeAction from '../../../../components/datagrid/studentPortal/HeadOrAssigneeAction'
import useResponsive from '../../../../Hooks/useResponsive'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import FromAndToDatePicker from '../../../../components/mui/FromAndToDatePicker'
import GrievanceJourney from '../../GrievanceJourney'
import Comment from '../../../../components/datagrid/studentPortal/Comment'

export const GrievanceType = [
  {
    id: 1,
    name: 'QUALITY OF CONTENT/CLASS',
    subTypes: [
      { id: 1, name: 'Poorly Structured Lessons' },
      { id: 2, name: 'Lack of Personalization' },
      { id: 3, name: 'Module Ineffectiveness' },
      { id: 4, name: 'Insufficient Conversation Sessions' },
      { id: 5, name: 'Class Structure Issues' },
      { id: 6, name: 'Limited Writing Practice' },
      { id: 7, name: 'Time Management Challenges' },
      { id: 8, name: 'Insufficient Support for Special Needs' },
    ],
  },
  {
    id: 2,
    name: 'TECHNICAL ISSUES',
    subTypes: [
      { id: 1, name: 'Platform Issues' },
      { id: 2, name: 'Device Compatibility Problems' },
      { id: 3, name: 'Poor Audio and Video Quality' },
      { id: 4, name: 'App Installation Issues' },
    ],
  },
  {
    id: 3,
    name: 'CUSTOMER SUPPORT',
    subTypes: [
      { id: 1, name: 'Slow Response Times' },
      { id: 2, name: 'Delayed Initial Contact' },
      { id: 3, name: 'Delayed Fee Information' },
      { id: 4, name: 'CRO Unavailability' },
      { id: 5, name: 'Miscommunication about Policies' },
      { id: 6, name: 'Delayed Demo Scheduling' },
      { id: 7, name: 'Delayed in starting Class' },
      { id: 8, name: 'Difficulty in Connecting' },
    ],
  },
  {
    id: 4,
    name: 'PRICING AND BILLING ISSUES',
    subTypes: [
      { id: 1, name: 'Refund Delays or Errors' },
      { id: 2, name: 'Changes or Discrepancies in Fees' },
    ],
  },
  {
    id: 5,
    name: 'STUDENT PROGRESS, FEEDBACKS AND MOTIVATION',
    subTypes: [
      { id: 1, name: 'Late Distribution of Progress Reports (Open House)' },
      { id: 2, name: 'Delays in Receiving Certificates' },
      { id: 3, name: 'Lack of Adequate Worksheets and Activities' },
      { id: 4, name: 'Dissatisfaction with Rewards System' },
    ],
  },
  {
    id: 6,
    name: 'PRIVACY AND SECURITY CONCERNS',
    subTypes: [{ id: 1, name: 'Privacy Concerns' }],
  },
  {
    id: 7,
    name: 'QUALITY OF TUTOR',
    subTypes: [
      { id: 1, name: 'Inadequate Tutor Qualifications' },
      { id: 2, name: 'Tardiness' },
      { id: 3, name: 'Technical Difficulties' },
      { id: 4, name: 'Inconsistent Camera Usage' },
      { id: 5, name: 'Class Rescheduling' },
      { id: 6, name: 'Improperly conducted exams or assessments.' },
      { id: 7, name: 'Time Management Issues' },
      { id: 8, name: 'Frequent Tutor Changes' },
      { id: 8, name: 'Extended absences or irregular attendance by tutor' },
    ],
  },
]

let statusJson = [
  { id: 2, value: 'Assigned to User' },
  { id: 3, value: 'Working is in Progress' },
  { id: 4, value: 'Resolved' },
  { id: 5, value: 'Rejected with comment' },
]
const HeadOrAssigneeTickets = () => {
  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  const { resData, loading } = useSelector((store) => store.studentPortal)
  const { data, meta } = resData
  const [status, setStatus] = useState([])
  const [grievenceType, setGrievenceType] = useState([]) //STORE NAME
  const [grievenceTypeId, setGrievenceTypeId] = useState([]) //STORE CORRESPONDING IDS TO SEND TO BACKEND
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  useEffect(() => {
    //GRIEVENCE TYPE ID IS STORING WHEN GRIEVEANCE FILTER WORKS
    const ids = grievenceType.map((name) => {
      const type = GrievanceType.find((type) => type.name == name)
      return type ? type.id : null
    })
    setGrievenceTypeId(ids)
  }, [grievenceType])

  const dispatchParams = {
    paginationData,
    status,
    grievenceTypeId,
    fromDate,
    toDate,
  }
  const [direction, setDirection] = useState('row')
  const mdDown = useResponsive('down', 'md')
  useEffect(() => {
    if (mdDown) {
      setDirection('column')
    } else {
      setDirection('row')
    }
  }, [mdDown])

  useEffect(() => {
    dispatch(savePaginationData({ ...paginationData, pageNum: 1 })) //ALWAYS PAGE NUMBER SET TO 1 WHEN SEARCH,STATUS,GRIEVENCETYPEID
  }, [search, status, grievenceTypeId])

  useEffect(() => {
    if (pageNum && gridSize) {
      dispatch(getAllGrievancesTickets(dispatchParams)) //GETTING ALL THE TICKETS FOR DEPARTMENT
    }
  }, [gridSize, pageNum, search, status, grievenceTypeId, toDate, fromDate])

  return (
    <GlobalWrapper title={'Assigned Tickets'}>
      {/* <Box sx={{ display: 'flex', gap: 1 }}>
        <Box flex={mdDown ? 1 : 0.5}>
          <FromAndToDatePicker
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fullWidth={true}
            // direction={direction}
          />
        </Box>
      </Box> */}

      <Stack mt={1} gap={1} direction={direction}>
        <Box flex={1}>
          <GlobalMultiSelect
            fullWidth={true}
            label={'Grievance Type Filter'}
            arrayState={grievenceType}
            arraySetState={setGrievenceType}
            data={GrievanceType}
            keys={{ id: 'id', value: 'name' }}
          />
        </Box>
        <Box flex={1}>
          <GlobalMultiSelect
            // sx={ { minWidth: '500px', maxWidth: '500px'}}
            fullWidth={true}
            label={'Status Filter'}
            arrayState={status}
            arraySetState={setStatus}
            data={statusJson}
            keys={{ id: 'id', value: 'value' }}
          />
        </Box>
      </Stack>

      <Box sx={{ mt: 1 }}>
        <Paper elevation={1}>
          <GlobalDatagrid
            tableHead={ticketTableHeader}
            rows={data || []}
            meta={meta?.totalRecords}
            rowUniqueId="sl_no"
            loading={loading}
            rowClick={true}
            actionComponent={
              <>
                <ActionDetails val={rowData?.studentName} />
                <GlobalAnchorList
                  label="Action"
                  component={
                    <HeadOrAssigneeAction
                      statusJson={statusJson.filter((item) => item.id !== 2)}
                      paginationData={paginationData}
                      row={rowData}
                      dispatchParams={dispatchParams}
                    />
                  }
                />
                <GlobalAnchorList
                  label="Grievence Journey"
                  component={<GrievanceJourney grievenceId={rowData?.grivanceId} />}
                />
                <GlobalAnchorList
                  label="Comment  &nbsp;"
                  component={<Comment row={rowData} dispatchParams={dispatchParams} />}
                />
              </>
            }
          />
        </Paper>
      </Box>
    </GlobalWrapper>
  )
}

export default HeadOrAssigneeTickets
