import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import useResponsive from "../../../../Hooks/useResponsive";
import FromAndToDatePicker from "../../../../components/mui/FromAndToDatePicker";
import { Box, Grid, Paper, Stack } from "@mui/material";
import GlobalMultiSelect from "../../../../components/mui/GlobalMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../../components/DatePicker/DatePickerCustom";
import dayjs from "dayjs";
import GlobalDatagrid from "../../../../components/mui/DataGrid/GlobalDatagrid";
import { employeeTableHeader } from "../../../../components/datagrid/hrDashboard/employeeTableHeader";
import { getAllDepartmenst } from "../../../../redux/features/postWorkingHoursSlice";
import {
  getAllLeaveTypes,
  getEmployeeStatuses,
  getEmployeeTypes,
  getWingsAndReportingHeadsUnderDepartments,
  totalEmployees,
} from "../../../../redux/features/hrDashboard";

const gender = [
  { id: 1, value: "Male" },
  { id: 2, value: "Female" },
];

const presentStatuses = [
  { id: 1, value: "Present Marked" },
  { id: 2, value: "Present Not Marked" },
];
const User = ({
  dashboardView,
  open,
  genderFilter,
  employeTypeFilter,
  employeeStatusFilter,
  leaveFilter,
  attendenceFilter,
}) => {
  const [direction, setDirection] = useState("row");
  const dispatch = useDispatch();
  const smDown = useResponsive("down", "sm");
  const [departmentNames, setDepartmentNames] = useState([]);
  const [genderNames, setGenderNames] = useState(
    genderFilter ? [genderFilter] : []
  );
  const [employeTypeName, setEmployeTypeName] = useState(
    employeTypeFilter ? [employeTypeFilter] : []
  );
  const [employeStatus, setEmployeStatus] = useState(
    employeeStatusFilter ? [employeeStatusFilter] : []
  );
  const [wingNames, setWingNames] = useState([]);
  const [reportingHeadNames, setReportingHeadNames] = useState([]);
  const [leaveName, setLeaveName] = useState(leaveFilter ? [leaveFilter] : []);
  const [presentNames, setPresentNames] = useState(
    attendenceFilter ? [attendenceFilter] : []
  );

  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const { departments } = useSelector((store) => store.postWorking);
  const {
    resData,
    loading,
    employeeStatuses,
    employeeTypes,
    wings,
    reportingHeads,
    leaveTypes,
  } = useSelector((store) => store.hrDashboard);
  const { data, meta } = resData;
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const dispatchParams = {
    // selectedDate,
    paginationData,
    departmentNames,
    genderNames,
    employeTypeName,
    employeStatus,
    reportingHeadNames,
    wingNames,
    leaveName,
    presentNames,
    fromDate,
    toDate,
  };

  useEffect(() => {
    //WHEN DEPARTMENT SELECT IT WILL CALL CORRESPONDING WINGS AND REPORTING HEADS UNDER THAT DEPARTMENT

    //FOR GETTING DEPARTMENT IDS TO FETCH
    let departmentIds = departments
      .filter((dept) =>
        departmentNames.some((depname) => dept.departmentName === depname)
      )
      .map((dept) => dept.departmentId);
    dispatch(getWingsAndReportingHeadsUnderDepartments(departmentIds)).then(
      (res) => {
        if (res.payload.status === "success" && wingNames.length > 0) {
          const selectedModifiedWings = wingNames.filter((selectedWingName) =>
            res.payload.data.wings.some(
              (wing) => wing.wing_name === selectedWingName
            )
          );
          setWingNames(selectedModifiedWings);
        }
      }
    );
  }, [departmentNames]);

  // useEffect(() => {
  //   if (smDown) {
  //     setDirection('column')
  //   } else {
  //     setDirection('row')
  //   }
  // }, [smDown])

  useEffect(()=>{
    if(leaveName.length==0 && presentNames.length==0){
      setFromDate("")
      setToDate("")
    }
  },[leaveName,presentNames])
  useEffect(() => {
    if (departments.length == 0 || employeeStatuses.length == 0) {
      dispatch(getAllDepartmenst());
      dispatch(getEmployeeStatuses());
      dispatch(getEmployeeTypes());
      dispatch(getAllLeaveTypes());
    }
  }, []);

  useEffect(() => {
    if (paginationData?.pageNum) {
      dispatch(totalEmployees(dispatchParams));
    }
  }, [
    gridSize,
    pageNum,
    search,
    selectedDate,
    departmentNames,
    genderNames,
    employeTypeName,
    employeStatus,
    reportingHeadNames,
    wingNames,
    leaveName,
    presentNames,
    toDate,
    fromDate
  ]);

  const content = () => {
    return (
      <>
        <Box sx={{ mb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box>
                <GlobalMultiSelect
                  disabled={loading}
                  label={"Department Filter"}
                  fullWidth
                  arrayState={departmentNames}
                  arraySetState={setDepartmentNames}
                  data={departments}
                  keys={{ id: "departmentId", value: "departmentName" }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box>
                <GlobalMultiSelect
                  disabled={genderFilter || loading}
                  fullWidth
                  label={"Gender Filter"}
                  arrayState={genderNames}
                  arraySetState={setGenderNames}
                  data={gender}
                  keys={{ id: "id", value: "value" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box>
                <GlobalMultiSelect
                  disabled={employeTypeFilter || loading}
                  label={"Employee Type Filter"}
                  fullWidth
                  arrayState={employeTypeName}
                  arraySetState={setEmployeTypeName}
                  data={employeeTypes}
                  keys={{ id: "id", value: "value" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box>
                <GlobalMultiSelect
                  disabled={employeeStatusFilter || loading}
                  label={"Employee Satus Filter"}
                  arrayState={employeStatus}
                  arraySetState={setEmployeStatus}
                  fullWidth
                  data={employeeStatuses}
                  keys={{ id: "id", value: "value" }}
                />
              </Box>
            </Grid>

            <>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Box>
                  <GlobalMultiSelect
                    disabled={loading}
                    label={"Wing Filter"}
                    fullWidth
                    arrayState={wingNames}
                    arraySetState={setWingNames}
                    data={wings?.flat()}
                    keys={{ id: "wing_id", value: "wing_name" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Box>
                  <GlobalMultiSelect
                    disabled={loading}
                    label={"Reporting Head Filter"}
                    fullWidth
                    arrayState={reportingHeadNames}
                    arraySetState={setReportingHeadNames}
                    data={reportingHeads}
                    keys={{ id: "reporting_head", value: "name" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Box>
                  <GlobalMultiSelect
                    disabled={presentNames.length>0||loading}
                    label={"Leave Types"}
                    fullWidth
                    arrayState={leaveName}
                    arraySetState={setLeaveName}
                    data={leaveTypes}
                    keys={{ id: "leaveTypeId", value: "leaveFullName" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Box>
                  <GlobalMultiSelect
                    disabled={leaveName.length>0||loading }
                    label={"Present Status"}
                    fullWidth
                    arrayState={presentNames}
                    arraySetState={setPresentNames}
                    data={presentStatuses}
                    keys={{ id: "id", value: "value" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box>
                  <FromAndToDatePicker
                    disabled={leaveName.length==0 && presentNames.length==0}
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                  />
                </Box>
              </Grid>
            </>
          </Grid>
        </Box>

        <Box>
          <Paper>
            <GlobalDatagrid
              tableHead={employeeTableHeader}
              rows={data || []}
              meta={meta?.totalRecords}
              rowUniqueId="sl_no"
              loading={loading}
            />
          </Paper>
        </Box>
      </>
    );
  };

  if (dashboardView) {
    return content();
  } else {
    return <GlobalWrapper title={"User"}>{content()}</GlobalWrapper>;
  }
};

export default User;
