import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Icon } from "@iconify/react";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { DeleteButton } from "../../../../components/mui/Buttons/Buttons";
import RemarkPopup from "./RemarkPopup";
import MakeNextPayment from "./MakeNextPayment";
import CartPaymentLogTbl from "./CartPaymentLogTbl";
import CartItemSubjects from "./CartItemSubjects";

export default function ActionMenu({
  children,
  handleInputChange,
  handleDelete,
  paidItem,
  item,
  totalCourses,
  update,
  paid,
  addNewPayment,
  paymentProcessingItems,
  paymentProcessing,
  setAvailableCartItems,
  setTotalCourses,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        sx={{ borderRadius: 10 }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Icon
          icon="iconamoon:menu-kebab-vertical-fill"
          height={22}
          width={22}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", pb: 1 }}>
            <Chip
              icon={
                <IconButton
                  sx={{
                    color: "text.primary",
                    animation: "spin 5s linear infinite",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotate(360deg)",
                      },
                      "100%": {
                        transform: "rotate(0deg)",
                      },
                    },
                  }}
                >
                  <Icon icon="solar:settings-outline" />
                </IconButton>
              }
              variant="outlined"
              size="small"
              color="primary"
              label="Actions"
            />
          </Box>
          <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 2 }} />
        </Box>
        {children}
        <Box sx={{ pt: 1 }} key={item.id}>
          {/* {update ? null : ( */}
          <motion.div
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.5 }}
          >
            <MenuItem>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={paid ? true : false}
                    checked={item.wishList || false}
                    onClick={() => setAnchorEl(null)}
                    onChange={(e) => handleInputChange(e, item.id, "wishList")}
                  />
                }
                label="wish list"
              />
            </MenuItem>
          </motion.div>
          {/* )} */}

          <motion.div
            initial={{ opacity: 0, x: -40 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.8 }}
          >
            {/* <MenuItem>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item.admissionFee === 0 ? false : true || false}
                      // onClick={() => setAnchorEl(null)}
                      onChange={(e) =>
                        handleInputChange(e, item.id, "admissionFee")
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Admission Fee"
                />
              </FormGroup>
            </MenuItem> */}
            <MenuItem>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={paid ? true : false}
                      checked={item.emi || false}
                      // onClick={() => setAnchorEl(null)}
                      onChange={(e) => handleInputChange(e, item.id, "emi")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="EMI"
                />
              </FormGroup>
            </MenuItem>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 1.1 }}
          >
            <MenuItem>
              <RemarkPopup
                paid={paid}
                update={update}
                handleClose={handleClose}
                item={item}
                handleInputChange={handleInputChange}
              />
            </MenuItem>
          </motion.div>
          {paid || paymentProcessing ? null : (
            <motion.div
              initial={{ opacity: 0, x: -60 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 1.4 }}
            >
              <MenuItem>
                <DeleteButton
                  size="small"
                  action={() => {
                    handleDelete(item.id, item.cartId);
                    setAnchorEl(null);
                  }}
                >
                  Delete
                </DeleteButton>
              </MenuItem>
            </motion.div>
          )}
          {addNewPayment && paidItem.emi != 1 ? (
            <motion.div
              initial={{ opacity: 0, x: -60 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 1.4 }}
            >
              <MenuItem>
                <MakeNextPayment
                  handleClose={handleClose}
                  paidItem={paidItem}
                />
              </MenuItem>
            </motion.div>
          ) : null}
          {paid || paymentProcessing ? (
            <motion.div
              initial={{ opacity: 0, x: -60 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 1.4 }}
            >
              <MenuItem>
                <CartPaymentLogTbl
                  item={item}
                  paid={paid}
                  paymentProcessing={paymentProcessing}
                  paidItem={paidItem}
                  paymentProcessingItems={paymentProcessingItems}
                />
              </MenuItem>
            </motion.div>
          ) : null}

          <motion.div
            initial={{ opacity: 0, x: -60 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 1.4 }}
          >
            <MenuItem>
              <CartItemSubjects
                item={item}
                update={update}
                setAvailableCartItems={setAvailableCartItems}
                handleInputChange={handleInputChange}
                setTotalCourses={setTotalCourses}
              />
            </MenuItem>
          </motion.div>
        </Box>
      </Menu>
    </div>
  );
}
