import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiRequest from '../../api/request'

const initialState = {
  loading: false,
  resData: {
    data: [],
    meta: {},
  },

  hrDashboardCounts: {
    TotalEmployees: 0,
    totalMaleEmployees: 0,
    totalFemaleEmployees: 0,
    totalResignedEmployees: 0,
    today_present: 2,
    lunch_count: 0,
    evening_food_count: 0,
    postWorkingHours: 0,
    totalLeaves: 0,
    totalWFH: 0,
 
    
  },
  departmentsAndInner: [],
  employeDetails: [],
  employeeStatuses:[],
  employeeTypes:[],
  wings:[],
  reportingHeads:[],
  leaveTypes:[]
}

export const getAllhrDashboardCounts = createAsyncThunk('hrDashboardSlice/getAllhrDashboardCounts', async (date) => {
  const res = await apiRequest({
    method: 'get',
    url: `analytics/basic-counts/${date}`,
  })
  return res
})

export const totalEmployees = createAsyncThunk('hrDashboardSlice/totalEmployees', async (dispatchParams) => {
  const {paginationData=null, departmentNames=null,genderNames=null,employeTypeName=null,employeStatus =null,wingNames=null,reportingHeadNames=null,leaveName=null,presentNames=null,fromDate=null,toDate=null} = dispatchParams
  let departmentFilter = 'filters[department]=equals:'
  let genderFilter = 'filters[sex]=equals:'
  let employeFilter = 'filters[EmploymentType]=equals:'
  let employeStatusFilter = 'filters[status]=equals:'
  let wingFilter='filters[wingName]=equals:'
  let reportHeadFilter='filters[reportingHead]=equals:'
  let leaveFilter='filters[leaveStatus]=equals:'
  let attendenceFilter='filters[AttendanceStatus]=equals:'



  if(departmentNames?.length>0){
    departmentFilter += departmentNames.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  if(genderNames?.length>0){
    genderFilter += genderNames.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  if(employeTypeName?.length>0){
    employeFilter += employeTypeName.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  if(employeStatus?.length>0){
    employeStatusFilter +=employeStatus.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  if(wingNames?.length>0){
    wingFilter +=wingNames.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  if(reportingHeadNames?.length>0){
    reportHeadFilter +=reportingHeadNames.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  if(leaveName?.length>0){
    leaveFilter +=leaveName.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  if(presentNames?.length>0){
    attendenceFilter +=presentNames.map((dName)=>`${dName}`).join(encodeURIComponent('+'))
  }
  const url = `users/all-users/?page=${paginationData?.pageNum}&limit=${
    paginationData?.gridSize
  }&search=${encodeURIComponent(paginationData?.search)}${departmentNames?.length>0?`&${departmentFilter}`:""}${genderNames?.length>0?`&${genderFilter}`:""}${employeTypeName?.length>0?`&${employeFilter}`:""}${employeStatus?.length>0?`&${employeStatusFilter}`:""}${wingNames?.length>0?`&${wingFilter}`:""}${reportingHeadNames?.length>0?`&${reportHeadFilter}`:""}${leaveName?.length>0?`&${leaveFilter}`:""}${presentNames?.length>0?`&${attendenceFilter}`:""}${fromDate?`&fromDate=${fromDate}`:""}${toDate?`&toDate=${toDate}`:""}`
  const res = await apiRequest({
    method: 'get',
    url: url,
  })
  return res
})

export const getEmployeeStatuses = createAsyncThunk('hrDashboardSlice/getEmployeeStatuses', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `general/empolyee-status`,
  })
  return res
})

export const getWingsAndReportingHeadsUnderDepartments = createAsyncThunk('hrDashboardSlice/getWingsAndReportingHeadsUnderDepartments', async (deptIds) => {
  const res = await apiRequest({
    method: 'post',
    url: `users/all-user/pre-data`,
    data:{deptIds:deptIds}
  })
  return res
})

export const getEmployeeTypes = createAsyncThunk('hrDashboardSlice/getEmployeeTypes', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `general/empolyee-type`,
  })
  return res
})

export const getAllLeaveTypes = createAsyncThunk('hrDashboardSlice/getAllLeaveTypes', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `leave/get-all-leave-types`,
  })
  return res
})


export const postWorkingEmployeeDetails = createAsyncThunk(
  'hrDashboardSlice/postWorkingEmployeeDetails',
  async (dispatchParams) => {
    const { selectedDate, paginationData, departmentName } = dispatchParams
    const url = `general/help-support/${selectedDate ? selectedDate : ''}?page=${paginationData?.pageNum}$limit=${
      paginationData?.gridSize
    }&search=${encodeURIComponent(paginationData?.search)} &filter=${
      departmentName ? 'department :equals:' + departmentName : ''
    }`
    console.log(url)

    const res = await apiRequest({
      method: 'get',
      url: url,
    })
    return res
  },
)

export const departmentDisplayDetails = createAsyncThunk('hrDashboardSlice/departmentDisplayDetails', async () => {
  const url = 'analytics/dept-counts'
  const res = await apiRequest({
    method: 'get',
    url: url,
  })
  return res
})

export const getEmployeeDetailsUnderHead = createAsyncThunk('hrDashboardSlice/getEmployeeDetailsUnderHead', async (headId) => {
  //GETTING WING ID OR DEPT ID ,FIRST CHECK WINGID IF NO THEN CHECK DEPARTMENT ID
  const url = `users/all-users-under/${headId}`
  const res = await apiRequest({
    method: 'get',
    url: url,
  })
  return res
})

const hrDashboardSlice = createSlice({
  name: 'hrDashboardSlice',
  initialState,
  reducers: {
    clearHrDashboardDetailedCardDatas: (state, action) => {
      return {
        ...state,
        resData: initialState.resData,
      }
    },
  },
  extraReducers: (builder) => {
    //..................GETING ALL DASHBOARD COUNTS................
    builder.addCase(getAllhrDashboardCounts.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getAllhrDashboardCounts.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        hrDashboardCounts: { ...action?.payload?.data },
      }
    })

    //..................GETING ALL EMPLOYEES DATAS................
    builder.addCase(totalEmployees.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(totalEmployees.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        resData: {
          ...state.resData,
          data: action.payload?.data,
          meta: action.payload?.meta,
        },
      }
    })

        //..................GETING ALL EMPLOYEES DATAS................
        builder.addCase(getWingsAndReportingHeadsUnderDepartments.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          }
        })
    
        builder.addCase(getWingsAndReportingHeadsUnderDepartments.fulfilled, (state, action) => {
          return {
            ...state,
            loading: false,
            wings:action.payload?.data?.wings,
           reportingHeads:action.payload?.data?.reportingHeads,

          }
        })
    //..................GETING ALL EMPLOYEES STATUS................
    builder.addCase(getEmployeeStatuses.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getEmployeeStatuses.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        employeeStatuses:action.payload.data
      }
    })

    //..................GETING ALL EMPLOYEES TYPES................
    builder.addCase(getEmployeeTypes.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getEmployeeTypes.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        employeeTypes:action.payload.data
      }
    })

        //..................GETING ALL LEAVE  TYPES................
        builder.addCase(getAllLeaveTypes.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          }
        })
    
        builder.addCase(getAllLeaveTypes.fulfilled, (state, action) => {
          return {
            ...state,
            loading: false,
            leaveTypes:action.payload.data
          }
        })
 

    //..................GETING   POST WORKING   MARKED EMPLOYEES DATAS................
    builder.addCase(postWorkingEmployeeDetails.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(postWorkingEmployeeDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        resData: {
          ...state.resData,
          data: action.payload?.data,
          meta: action.payload?.meta,
        },
      }
    })

    //..................GETTING DEPARTMENT OVERVIEW................
    builder.addCase(departmentDisplayDetails.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(departmentDisplayDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        departmentsAndInner: action?.payload?.data,
      }
    })

    //..................GETTING EMPLOYEE DETAILS ................
    builder.addCase(getEmployeeDetailsUnderHead.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getEmployeeDetailsUnderHead.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        employeDetails: action?.payload?.data,
      }
    })
  },
})

export const { clearHrDashboardDetailedCardDatas } = hrDashboardSlice.actions
export default hrDashboardSlice.reducer
