import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const UserAction = ({id}) => {
    const navigate=useNavigate()
const onclickAction=()=>{
navigate(`/my-workspace/editemployee/${id}`)
}
  return (
   <>
   <Button onClick={onclickAction} variant='outlined'>Edit</Button>
   </>
  )
}

export default UserAction