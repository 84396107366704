import { Avatar, Box, Button, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

export const ProfileUserCard = React.memo(({ usersData }) => {
  const handleAvatarError = useCallback((e) => {
    e.target.src = 'https://logodix.com/logo/1707088.png'
  }, [])
  
  return (
    <>
      <Box>
        <Grid container spacing={5}>
          {usersData.map((user) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={user?.userID || user?.sl_no}>
              <Paper
                elevation={6}
                sx={{
                  p: 2,
                  borderRadius: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  gap: 1.5,
                  // height: 170,
                  backgroundColor: '#f9f9f9',
                }}>
                <Box
                  sx={{
                    position: 'relative',
                    width: 120,
                    height: 120,
                    mb: 1,
                  }}>
                  <Avatar
                    src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${user?.photo}.jpg`}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    // onError={handleAvatarError}
                    // src='https://logodix.com/logo/1707088.png'
                    // alt={user.name}
                    sx={{
                      width: '100%',
                      height: '100%',
                      border: '3px solid #1976d2',
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: -14,
                      left: 45,
                      right: 0,
                      width: 30,
                      height: 30,
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      boxShadow: 1,
                    }}>
                    {user?.name?.charAt(0)}
                  </Box>
                </Box>

                <Typography variant="h6" sx={{ fontWeight: 600, color: '#333' }}>
                  {user?.name}
                </Typography>
                {/* <Typography variant="body2" sx={{ color: '#666' }}>
                {user.dept_name}
              </Typography> */}
                {/* <Typography
                variant="caption"
                sx={{
                  px: 2,
                  py: 0.5,
                  backgroundColor: '#e0f7fa',
                  color: '#00796b',
                  borderRadius: 2,
                }}
              >
                {user.employment_status_name}
              </Typography> */}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* <Box>
      <Button>Next</Button>
    </Box> */}
    </>
  )
})

export const ProfileUserCardSkeleton = () => {
  return (
    <Grid container spacing={2}>
      {Array.from(new Array(4)).map((_, index) => (
        <Grid item xs={12} md={4} lg={3} key={index}>
          <SkeletonDesign key={index} />
        </Grid>
      ))}
    </Grid>
  )
}

const SkeletonDesign = () => {
  return (
    <Paper elevation={6}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Skeleton
            animation="wave"
            sx={{
              width: 300,
              height: 200,
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Skeleton animation="wave" variant="circular" width={60} height={60} />
        </Box>

        <Box sx={{ p: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Skeleton animation="wave" width={200} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Skeleton animation="wave" width={100} />
          </Box>
        </Box>
        {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Stack spacing={2} direction="row">
          <Skeleton animation="wave" width={80} height={100} />
          <Skeleton animation="wave" width={80} height={100} />
          <Skeleton animation="wave" width={80} height={100} />
        </Stack>
      </Box> */}
      </Box>
    </Paper>
  )
}
