import React, { useState } from 'react'
import { ApproveButton, RejectButton, SubmitButton, ViewButton } from '../../../mui/Buttons/Buttons'
import { useSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import GlobalDialog from '../../../mui/Dialogue/GlobalDialog'
import { Box, Button, Stack, TextField } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import GlobalSelectField from '../../../mui/GlobalSelectField'
import { getAllGrievancesTickets, updateAssignation } from '../../../../redux/features/studentPortalSlice'
let statusJson = [
  // { id: 0, value: 'pending' },
  // { id: 1, value: 'Assigned To a Department' },
  // { id: 2, value: 'Assigned to User'},
  // { id: 3, value: 'Working is in Progress'},
  // { id: 4, value: 'Resolved'},
  { id: 5, value: 'Rejected with comment' },
  // { id: 6, value: 'Approved by Head'},
  { id: 7, value: 'Closed' },
]

const TicketVerification = ({ row, dispatchParams }) => {
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { loading } = useSelector((store) => store.studentPortal)

  const initialValues = {
    status: '',
    comment: '',
  }
  const schema = Yup.object({
    // status: Yup.number().required('Status Required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(updateAssignation({...values,id:row?.grivanceId})).then((res) => {
        if (res.payload.status === 'error') {
          enqueueSnackbar(res.payload.message, {
            variant: 'error',
          })
        } else if (res.payload.status === 'success') {
          handleCancel()
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
      
          enqueueSnackbar(res.payload.message, {
            variant: 'success',
          })
      dispatch(getAllGrievancesTickets(dispatchParams))
        }
      })
    },
  })
  const handleCancel = () => {
    setOpen(false)
    formik.resetForm()
  }
  return (
    <>
      <GlobalDialog
        maxWidth={'sm'}
        fullWidth
        title={'One-Time Closure'}
        open={open}
        setOpen={setOpen}
        closeButton={true}
        closeCondition={handleCancel}
        popupButton={
          <>
            <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
              Close
            </Button>
          </>
        }
        content={
          <>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Stack gap={2}>
                  <GlobalSelectField
                    disabled={loading}
                    options={{
                      formik: formik,
                      label: 'Status',
                      name: 'status',
                      sx: {
                        width: '100%',
                      },
                      data: statusJson,

                      keys: {
                        id: 'id',
                        value: 'value',
                      },
                    }}
                  />
                  <Box>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="comment"
                      label="Comment"
                      name="comment"
                      value={formik.values.comment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.comment && Boolean(formik.errors.comment)}
                      helperText={formik.touched.comment && formik.errors.comment}
                    />
                  </Box>
                  <Box>
                    <SubmitButton disabled={loading} type="submit">
                      {loading ? 'Closing...' : 'Close'}
                    </SubmitButton>
                  </Box>
                </Stack>
              </Form>
            </FormikProvider>
          </>
        }
      />
    </>
  )
}

export default TicketVerification
