import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DatePickerCustom from '../DatePicker/DatePickerCustom'
import dayjs from 'dayjs'

const FromAndToDatePicker = ({
  direction = 'row',
  fromLabel = 'From Date',
  toLabel = 'To Date',
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  fullWidth,
  disabled
}) => {
  return (
    <Stack gap={1} direction={direction}>
      <Box flex={1}>
        <DatePickerCustom
        disabled={disabled}
          fullWidth={fullWidth}
          value={fromDate?dayjs(fromDate):null}
          setValue={(date) => {
            if (setFromDate) {
              setFromDate(date? date.format('YYYY-MM-DD'):"")
            }
          }}
          viewDetails={['year', 'month', 'day']}
          error={false}
          label={fromLabel}
        />
      </Box>
      <Box flex={1}>
        <DatePickerCustom
         disabled={disabled}
          fullWidth={fullWidth}
          value={toDate?dayjs(toDate):null}
          setValue={(date) => {
            if (setToDate ) {
              setToDate(date? date.format('YYYY-MM-DD'):"")
            }
          }}
          viewDetails={['year', 'month', 'day']}
          error={false}
          label={toLabel}
        />
      </Box>
    </ Stack>
  )
}

export default FromAndToDatePicker
