import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {getAllGrievancesTickets, updateAssignation } from '../../../redux/features/studentPortalSlice'
import GlobalDialog from '../../mui/Dialogue/GlobalDialog'
import { UpdateButton } from '../../mui/Buttons/Buttons'
import { Autocomplete, Box, Stack, TextField } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import GlobalSelectField from '../../mui/GlobalSelectField'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'
const HeadOrAssigneeAction = ({row, statusJson, dispatchParams}) => {
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { loading, departments, employees } = useSelector((store) => store.studentPortal)
    const { paginationData } = useSelector((state) => state.globalDataGrid)
    const [open, setOpen] = useState(false)
  
    const initialValues = {
      status: row.statusId !=2 ?  row.statusId : '',
      comment:'',
    }
    const schema = Yup.object({
      status: Yup.number().required('Status Required'),
      // comment: Yup.string().required('Assignee Remarks  Required'),
    })
  
    const formik = useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: schema,
     onSubmit: (values) => {
       dispatch(updateAssignation({...values,id:row?.grivanceId})).then((res) => {
         if (res.payload.status === 'error') {
           enqueueSnackbar(res.payload.message, {
             variant: 'error',
           })
         } else if (res.payload.status === 'success') {
           handleCancel()
           dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
       
           enqueueSnackbar(res.payload.message, {
             variant: 'success',
           })
       dispatch(getAllGrievancesTickets(dispatchParams))
         }
       })
     },
    })
  
  
    const handleCancel = () => {
      setOpen(false)
      formik.resetForm()
    }

  return (
    <GlobalDialog
    maxWidth={'sm'}
    fullWidth
    title={'Action'}
    open={open}
    setOpen={setOpen}
    closeButton={true}
    closeCondition={handleCancel}
    popupButton={
      <>
        <UpdateButton
          //   disabled={row.status !== 'Pending'}
          size="small"
          action={() => setOpen(true)}>
          Update
        </UpdateButton>
      </>
    }
    content={
      <>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Stack gap={2}>
              <GlobalSelectField
                disabled={loading}
                options={{
                  formik: formik,
                  label: 'Status',
                  name: 'status',
                  sx: {
                    width: '100%',
                  },
                  data: statusJson,

                  keys: {
                    id: 'id',
                    value: 'value',
                  },
                }}
              />
              
              <Box>
                <TextField
                  autoComplete="off"
                  fullWidth
                  id="comment"
                  label="Comment"
                  name="comment"
                  value={formik.values.comment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment}
                />
              </Box>

              <Box>
                <UpdateButton disabled={loading} type="submit">
                  {loading ? 'Updating...' : 'Update'}
                </UpdateButton>
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </>
    }
  />
  )
}

export default HeadOrAssigneeAction