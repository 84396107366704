import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../mui/Dialogue/GlobalDialog'
import { AddButton, CancelButton, SubmitButton, UpdateButton } from '../../../mui/Buttons/Buttons'
import { Box, Stack, TextField, Typography } from '@mui/material'
import DatePickerCustom from '../../../DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import { LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import {
  createPostWorkingRequest,
  getAllPostWorkingHours,
  updatePostWorking,
} from '../../../../redux/features/postWorkingHoursSlice'
import useResponsive from '../../../../Hooks/useResponsive'
const AddOrUpdateWorkingHours = ({ row, dispatchParams }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const [workingDate, setWorkingDate] = useState(row ? dayjs(row.date) : dayjs())
  const [open, setOpen] = useState(false)
  const [from, setFrom] = useState(dayjs().hour(20).minute(0).second(0))
  const [to, setTo] = useState(row ? dayjs(row?.time?.split('-')?.[1], 'HH:mm') : dayjs().hour(21).minute(0).second(0))
  const [remarks, setRemarks] = useState(row ? row.remarks : '')
  const { resData, loading } = useSelector((store) => store.postWorking)
  const[isDisabled,setIsDisabled]=useState(false)
  const smDown = useResponsive("down", "sm");
  const handleCancel = () => {
    setOpen(false)
    setRemarks('')
  }
  const submit = () => {
    const formatedData = {
      fromTime: from.format('HH:mm'),
      toTime: to.format('HH:mm'),
      remarks: remarks,
      date: workingDate.format('YYYY-MM-DD'),
    }
    if (row) {
      const updatedData = { fromTime: from.format('HH:mm'), toTime: to.format('HH:mm'), remarks: remarks,id:row?.id }
      dispatch(updatePostWorking(updatedData)).then((res) => {
        if (res.payload.status === 'error') {
          enqueueSnackbar(res.payload.message, {
            variant: 'error',
          })
        } else if (res.payload.status === 'success') {
          setOpen(false)
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
          dispatch(getAllPostWorkingHours(dispatchParams))
          enqueueSnackbar(res.payload.message, {
            variant: 'success',
          })
        }
      })
    } else {
      dispatch(createPostWorkingRequest(formatedData)).then((res) => {
        if (res.payload.status === 'error') {
          enqueueSnackbar(res.payload.message, {
            variant: 'error',
          })
        } else if (res.payload.status === 'success') {
          handleCancel()
          enqueueSnackbar(res.payload.message, {
            variant: 'success',
          })
          dispatch(getAllPostWorkingHours(dispatchParams))
        }
      })
    }
  }

  useEffect(()=>{
    const now=dayjs()
    const startTime=dayjs().set('hour',14).set('minute',29) //2:30pm
    const endTime=dayjs().set('hour',17).set('minute',30) //5:30pm
  if(now.isAfter(startTime)&& now.isBefore(endTime)){
    setIsDisabled(false)
  }else{
    setIsDisabled(false)
  }

  },[open])
  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth
      title={<>
      <Typography variant="body1">PWH Request Raising Time : 2:30 PM - 5:30 PM</Typography>
      </>}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          {row ? (
            <UpdateButton disabled={row?.status !== 'Pending' || row?.editor==0} size="small" action={() => setOpen(true)}>
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size= {smDown?"small":"large"}
              action={() => {
                setOpen(true)
              }}>
              Raise Request
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <Box sx={{ mb: 1 }}>
            <DatePickerCustom
              value={workingDate}
              disabled={true}
              label={'Working Date'}
              viewDetails={['year', 'month', 'day']}
              error={false}
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'TimePicker']}>
                <TimePicker
                  disabled
                  label="From"
                  value={from || dayjs().hour(20).minute(0).second(0)}
                  onChange={(newValue) => setFrom(newValue)}
                />
                <TimePicker
                  label="To"
                  value={to || dayjs().hour(21).minute(0).second(0)}
                  onChange={(newValue) => setTo(newValue)}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box sx={{ mb: 1 }}>
            <TextField
              fullWidth
              id="outlined-controlled"
              label="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              autoComplete='off'
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Stack direction={'row'} spacing={1}>
                <CancelButton action={handleCancel}>Cancel</CancelButton>
                {row ? (
                  <UpdateButton action={submit} disabled={loading} type="submit">
                    {loading ? 'Updating...' : 'Update'}
                  </UpdateButton>
                ) : (
                  <SubmitButton action={submit} disabled={isDisabled || loading} type="submit">
                    {loading ? ' Submitting...' : 'Submit'}
                  </SubmitButton>
                )}
              </Stack>
            </Box>
          </Box>
        </>
      }
    />
  )
}

export default AddOrUpdateWorkingHours
